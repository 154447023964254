import React, { useEffect, useState } from 'react';

import { UserProps } from '../../types/types.ds';
import { observer } from 'mobx-react';
import LocalOrderStore from './models/LocalOrderStore';
import { Loading } from '../../components/loading/loading';
import { POSCompanyStore } from './models/POSCompanyStore';
import { useParams } from 'react-router';
import axios from 'axios';
import OrderForm from './OrderForm';

const PointOfSalesForm = ({ user }: { user: UserProps }) => {
    const [isLoading, setIsLoading] = useState(false);

    const params = useParams();

    const localStore = LocalOrderStore;

    //@ts-ignore
    const { table_uid } = params;
    const table = POSCompanyStore.table;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (POSCompanyStore.table?.current_order?.grand_total) {
            localStore.setOrder(POSCompanyStore.table.current_order);
        }
    }, [POSCompanyStore.table?.current_order?.grand_total]);

    useEffect(() => {
        setIsLoading(true);

        if (table?.current_order) {
            localStore.setOrder(table.current_order);
            setIsLoading(false);
        } else {
            localStore.setOrder({
                id: -1,
                uid: '',
                number: '',
                created_time: 'None',
                created_by: user,
                identification_number: '9999999999999',
                identification_type: null,
                first_name: 'Consumidor',
                last_name: 'Final',
                billing_name: 'Consumidor Final',
                customer_email: '',
                shipping_street: '--',
                shipping_city: '',
                order_items: [],
                status: 'pending',
                phone: '',
                owner_related: user as any,
                grand_total: 0,
                shipping_cost: '0',
                pickup_time: '',
                completed: false,
                status_display: '',
                estimated_arrival_time: '',
                discount: '0',
                subtotal: 0,
                total_without_tax: 0,
                total_taxes: 0,
                type: 'on-site',
                source: 'web',
                invoices: [],
                total_with_taxes: 0,
            });
            setIsLoading(false);
        }

        const cancelRequest1 = axios.CancelToken.source();

        POSCompanyStore.getTableById(table_uid, cancelRequest1).then(res => {
            if (POSCompanyStore.table?.current_order) {
                localStore.setOrder(POSCompanyStore.table.current_order);
            }

            setIsLoading(false);
        });

        return () => {
            cancelRequest1.cancel();
        };
    }, [table_uid]);

    const order = localStore?.order;

    if (isLoading) {
        return <Loading />;
    }

    if (!order || !table) {
        return <div />;
    }

    return <OrderForm order={order} table={table} isLoading={POSCompanyStore.isLoadingTable} />;
};

export default observer(PointOfSalesForm);
