/**
 * Food Delivery - React Native Template
 *
 * @format
 * @flow
 */

import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { UserStore } from '../../stores/UserStore';
import axios from 'axios';
import { TableItem } from './TableItem';
import { AuthenticationStore } from '../../stores/AuthenticationStore';
import { Redirect, useHistory } from 'react-router-dom';
import { POSCompanyStore } from './models/POSCompanyStore';
import { Button, Row, Space, Typography } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { Divider } from '@mui/material';
import { AddOutlined } from '@mui/icons-material';
import { Loading } from '../../components/loading/loading';

const TablesList = () => {
    const [isLoading, setLoading] = useState<boolean>(true);

    const navigation = useHistory();

    useEffect(() => {
        if (AuthenticationStore.isAuthenticated && UserStore.user?.uid) {
            setLoading(true);
            const cancelRequest1 = axios.CancelToken.source();
            POSCompanyStore.getTables(cancelRequest1).finally(() => {
                setLoading(false);
            });
        }
    }, [AuthenticationStore.isAuthenticated, UserStore.user?.uid]);

    const tables = POSCompanyStore.tables;

    const tables_options = tables.map(t => {
        return (
            <TableItem key={t.uid} occupied={!!t.current_order?.id} selected={false} height={280}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        height: '100%',
                    }}
                >
                    <div className="flex flex-row justify-between items-baseline h-1/5">
                        <Typography.Title
                            level={3}
                            style={{
                                color: 'black',
                                width: '80%',
                            }}
                        >
                            {t.name}
                        </Typography.Title>
                    </div>
                    <Divider />

                    <div className={'h-4/5'}>
                        <div className={'h-3/4'}>
                            {t?.current_order?.uid && (
                                <>
                                    <div className="flex flex-col justify-center items-center">
                                        <Typography.Title
                                            level={5}
                                            style={{
                                                color: '#000',
                                            }}
                                        >
                                            {t.current_order.first_name} {t.current_order.last_name}
                                        </Typography.Title>

                                        <Typography.Title
                                            level={5}
                                            style={{
                                                color: 'black',
                                                margin: 0,
                                            }}
                                        >
                                            ${t.current_order?.grand_total.toFixed(2)}
                                        </Typography.Title>
                                    </div>
                                </>
                            )}
                        </div>

                        <div className={'h-1/4'}>
                            {t?.current_order?.uid ? (
                                <div className={'w-full mt-2 flex flex-col justify-between'}>
                                    <Button
                                        type={'primary'}
                                        icon={<EditOutlined />}
                                        onClick={() => {
                                            POSCompanyStore.setTable(t);
                                            navigation.push(`/table/${t.uid}/`);
                                        }}
                                    >
                                        Ver
                                    </Button>

                                    {/*<Button*/}
                                    {/*    id={'button-print'}*/}
                                    {/*    className={'mt-2'}*/}
                                    {/*    loading={isPrinting}*/}
                                    {/*    onClick={() => {*/}
                                    {/*        const order_id = t.current_order?.uid;*/}
                                    {/*        if (order_id) {*/}
                                    {/*            handlePrint(order_id);*/}
                                    {/*        }*/}
                                    {/*    }}*/}
                                    {/*    type={'primary'}*/}
                                    {/*    icon={<PrinterOutlined />}*/}
                                    {/*>*/}
                                    {/*    Imprimir*/}
                                    {/*</Button>*/}
                                </div>
                            ) : (
                                <div className={'mt-2 w-full text-center'}>
                                    <Button
                                        type={'primary'}
                                        icon={<AddOutlined />}
                                        onClick={() => {
                                            POSCompanyStore.setTable(t);
                                            navigation.push(`/table/${t.uid}/`);
                                        }}
                                    >
                                        Agregar
                                    </Button>
                                </div>
                            )}

                            {/*{t.current_order?.uid && (*/}
                            {/*    <div className={'mt-2 w-full text-right'} title={'Presiona para completar esta mesa'}>*/}
                            {/*        <Button*/}
                            {/*            style={{*/}
                            {/*                width: '100%',*/}
                            {/*            }}*/}
                            {/*            onClick={() => handleClickComplete(t)}*/}
                            {/*            type={'primary'}*/}
                            {/*            danger*/}
                            {/*            icon={<CheckOutlined />}*/}
                            {/*        >*/}
                            {/*            Completar*/}
                            {/*        </Button>*/}
                            {/*    </div>*/}
                            {/*)}*/}
                        </div>
                    </div>
                </div>
            </TableItem>
        );
    });

    if (!AuthenticationStore.isAuthenticated) {
        return <Redirect to={'/login/'} />;
    }

    if (!UserStore.user) {
        return null;
    }

    return (
        <div>
            <div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    {/*<Typography.Title*/}
                    {/*    style={{*/}
                    {/*        fontWeight: '700',*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    Mesas*/}
                    {/*</Typography.Title>*/}

                    <Row
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginTop: 10,
                        }}
                    >
                        {isLoading && tables_options.length == 0 && (
                            <Space>
                                <Loading />
                            </Space>
                        )}
                        {tables_options}
                    </Row>
                </div>
            </div>
        </div>
    );
};

export default observer(TablesList);
