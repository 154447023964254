import React from 'react';
import { Button, Form, Input, InputNumber, message, Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import Settings from './Settings';
import PrinterStore from '../../stores/PrinterStore';
import axios from 'axios';

const PageSettings = () => {
    const server = PrinterStore.server;
    const host = PrinterStore.host;
    const port = PrinterStore.port;

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
            }}
        >
            <div className="md:container md:mx-auto">
                <Typography.Title level={3}>Configuración de impresora</Typography.Title>
                <Settings
                    server={server}
                    port={port}
                    host={host}
                    handleChange={(server, host1, port1) => {
                        PrinterStore.setServer(server);
                        PrinterStore.setHost(host1);
                        PrinterStore.setPort(port1);
                    }}
                />

                <a
                    onClick={() => {
                        axios
                            .get(`https://${PrinterStore.server}:4101/`)
                            .then(() => {
                                message.success('Conectado a la impresora');
                            })
                            .catch(e => {
                                message.error('No se pudo conectar a la impresora');
                                message.error(e.toString());
                            });
                    }}
                >
                    Probar la impresora
                </a>
            </div>
        </div>
    );
};

export default observer(PageSettings);
