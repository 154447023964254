import { Tag } from 'antd';
import React from 'react';

const OrderStatusTag = ({ status }: { status: string }) => {
    switch (status) {
        case 'in-progress':
            return <Tag color="yellow">Abierta</Tag>;
        case 'delivered':
            return <Tag color="success">Completada</Tag>;
        default:
            return null;
    }
};

export default OrderStatusTag;
