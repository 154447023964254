import { action, observable, computed, runInAction } from 'mobx';
import { get, patch, post } from '../utils/request';
import dayjs from 'dayjs';
import { OrderProps } from '../types/types.ds';
import { CancelTokenSource } from 'axios';
import { LocalOrderStore } from '../stores/LocalOrderStore';

class Order {
    @observable isCreatingInvoice = false;
    @observable loading = true;
    @observable orders: Array<OrderProps> = [];
    @observable next_page = null;
    @observable total_orders = 0;
    @observable selected?: OrderProps;

    @action setSelected = async (order: OrderProps) => {
        this.selected = order;
        await setTimeout(() => {}, 300);
    };

    @action getOrders(params?: any) {
        this.loading = true;

        get('/restaurant/local-orders/', params)
            .then(r => {
                this.orders = r.data.results;
                this.next_page = r.data?.links.next;
                this.total_orders = r.data.count;
            })
            .catch(err => console.log(JSON.stringify(JSON.stringify(err))))
            .finally(() => {
                runInAction(() => {
                    this.loading = false;
                });
            });
    }

    /**
     * Update order in the  list and on the detail if is selected
     * @param uid
     */
    @action async getOrder(uid: string) {
        await get(`/restaurant/local-orders/${uid}/`)
            .then(r => {
                runInAction(() => {
                    //                     this.orders = this.orders.map(order => {
                    //                         if (order.uid === uid) {
                    //                             return r.data;
                    //                         }
                    //                         return order;
                    //                     });
                    //                     if (this.selected && this.selected?.uid === uid) {
                    this.selected = r.data;
                    //                     }
                });
            })
            .catch(err => console.log(JSON.stringify(JSON.stringify(err))))
            .finally(() => {});
    }

    @action getMoreOrders() {
        if (this.next_page) {
            get(`/restaurant/local-orders/`, {
                page: this.next_page,
            })
                .then(r => {
                    this.orders = [...this.orders, ...r.data.results];
                    this.next_page = r.data?.links.next;
                })
                .catch(err => console.log(JSON.stringify(err)));
        }
    }

    @action get(uid: string, cancelRequest: CancelTokenSource) {
        return get(`/restaurant/local-orders/${uid}/`, cancelRequest)
            .then(r => {
                runInAction(() => {
                    this.selected = r.data;
                });
            })
            .catch(err => console.log(JSON.stringify(err)));
    }

    @computed get pendingOrders() {
        return this.orders.filter(order => order.status !== 'delivered' && order.status !== 'cancelled').length;
    }

    @action async placeOrder(payload = {}) {
        const r = await post('/orders/', payload);

        return r.data;
    }

    @action getOrCreateRoomByOrder(order_id: string) {
        return get(`/orders/${order_id}/room/`);
    }

    @action setOrder(payload: OrderProps) {
        let found = false;
        this.orders = OrderStore.orders.map(item => {
            if (item.uid === payload.uid) {
                found = true;
                return {
                    ...item,
                    ...payload,
                };
            }
            return item;
        });

        if (!found) {
            this.orders = [payload, ...this.orders];
        }

        return found;
    }

    @action cancelOrder(uid: string) {
        return post(`/orders/${uid}/cancel/`)
            .then(r => {})
            .catch(err => console.log(JSON.stringify(err)));
    }

    @action async updateOrder(uid: string, payload = {}) {
        const r = await patch(`/orders/${uid}/`, payload);
        this.setOrder(r.data);
        return r.data;
    }

    @action updateStatus(uid: string, status: any, pickup_time?: string) {
        this.orders = this.orders.map(item => {
            if (item.uid === uid) {
                this.selected = {
                    ...item,
                    status: status,
                };
                if (pickup_time) {
                    item.pickup_time = dayjs(pickup_time).format('YYYY-MM-DDTHH:mm:ss');
                    this.selected['pickup_time'] = dayjs(pickup_time).format('YYYY-MM-DDTHH:mm:ss');
                }
                return {
                    ...item,
                    status: status,
                };
            }
            return item;
        });

        const data: any = {
            status: status,
        };

        if (pickup_time) {
            data['pickup_time'] = pickup_time;
        }

        return patch(`/orders/${uid}/`, data)
            .then(r => {})
            .catch(err => {
                //Todo show alert
                // alert(JSON.stringify(err?.response?.data));
                this.getOrders();
            });
    }

    @action requestDeliveryService(payload = {}) {
        return post('/orders/delivery-for-stores/', payload);
    }

    @action applyCoupon(payload = {}) {
        return post(`/coupons/apply/`, payload);
    }

    /**
     * Update order in the  list and on the detail if is selected
     * @param uid
     */
    @action
    async getOrderReceipt(uid: string) {
        const r = await get(`/orders/${uid}/receipt/`);
        return r.data;
    }

    @action
    async createInvoice(uid: string) {
        this.isCreatingInvoice = true;
        const r = await get(`/restaurant/local-orders/${uid}/invoice/`).finally(() => {
            this.isCreatingInvoice = false;
        });
        this.setInvoice(r.data);
        return r.data;
    }

    @action
    async setInvoice(invoice: any) {
        LocalOrderStore.setInvoice(invoice);
    }

    async validateInvoice(uid: string) {
        const r = await get(`/restaurant/invoices/${uid}/validate/`);
        this.setInvoice(r.data);
    }

    @action
    async authorizeInvoice(uid: string) {
        const r = await get(`/restaurant/invoices/${uid}/authorize/`);
        console.log('authorizeInvoice', r.data);
        this.setInvoice(r.data);
    }

    @action
    async getRide(uid: string, cancelRequest: CancelTokenSource) {
        const r = await get(`/restaurant/invoices/${uid}/ride/`, {}, cancelRequest, 'blob');
        return r.data;
    }

    @action
    async sendBillToCustomer(uid: string) {
        const r = await get(`/restaurant/invoices/${uid}/send-email/`);
        this.setInvoice(r.data);
        return r;
    }
}

export const OrderStore = new Order();
