import React, { useState } from 'react';
import { Link, Redirect, useHistory, useLocation } from 'react-router-dom';
import { AuthenticationStore } from '../../stores/AuthenticationStore';
import Logo from '../../assets/images/logo.png';
import { Button, Checkbox, Form, Input, notification, Row } from 'antd';
// @ts-ignore
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { GoogleLogin } from 'react-google-login';
import Toast from '../../components/Toast/toast';
import FacebookIcon from '@mui/icons-material/Facebook';
import GoogleIcon from '@mui/icons-material/Google';

export const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

interface Props {
    onSuccess: () => void;
}

export const Login = (props: Props) => {
    const [loading, setLoading] = useState(false);

    const [email, setEmail] = React.useState('');
    const [resettingPassword, setResettingPassword] = React.useState<boolean>(false);
    const [sendingRecoveryForm, setSendingRecoveryForm] = React.useState<boolean>(false);

    const [action, setAction] = useState<'Login' | 'Register'>('Login');

    const history = useHistory();

    const loginNow = (values: any) => {
        console.log(values);
        const { email, password } = values;
        // if (!email || !password) {
        //     return;
        // }
        AuthenticationStore.login(email, password)
            .then(() => {
                props.onSuccess();
            })
            .catch(() => {
                notification.warn({
                    message: 'Sus datos son incorrectos',
                    description: 'Intenta nuevamente',
                });
            });
    };

    const signUp = (values: any) => {
        const { first_name, last_name, email, password } = values;

        setLoading(true);

        AuthenticationStore.createAccount({
            first_name: first_name,
            last_name: last_name,
            email: email,
            phone: '',
            password1: password,
            password2: password,
        })
            .then(response => {
                setLoading(false);
                props.onSuccess();
            })
            .catch(error => {
                // Todo better handler of errors for unique email, bad password, bad emails

                let message = 'Algo no fue bien, intenta nuevamente';

                if (error?.response?.status === 400) {
                    if (error.response?.data?.email) {
                        message = JSON.stringify(error.response?.data?.email);
                    } else if (error.response?.data?.password1) {
                        message = 'Parece que tu contraseña es insegura';
                    } else {
                        message = JSON.stringify(error.response);
                    }
                } else {
                    message = JSON.stringify(error.response);
                }
                notification.error({
                    message: 'Error',
                    description: message,
                });

                setLoading(false);
            });
    };

    const googleLogin = (r: any) => {
        const accessToken = r.accessToken;
        AuthenticationStore.loginWithGoogle(accessToken)
            .then(() => {
                props.onSuccess();
            })
            .catch(err => {
                console.log(JSON.stringify(err?.response?.data));
            });
    };

    /**
     * Attempt a login using the Facebook login dialog asking for default permissions.
     */
    const facebookLogin = (data: any) => {
        AuthenticationStore.loginWithFacebook(data.accessToken)
            .then(() => {
                props.onSuccess();
            })
            .catch(err => {
                console.log(JSON.stringify(err));
            });
    };

    const sendPasswordReset = (e: any) => {
        e.preventDefault();

        setSendingRecoveryForm(true);

        AuthenticationStore.resetPassword(email)
            .then(() => {
                Toast.sweet('Se ha enviado las instrucciones a tu correo electronico');
                setResettingPassword(false);
            })
            .catch(() => {
                Toast.show('Error, por favor intenta nuevamente');
            })
            .finally(() => {
                setSendingRecoveryForm(false);
            });
    };

    if (resettingPassword) {
        return (
            <div>
                <figure>
                    <div
                        className={'my-2'}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <img className="r" src={Logo} width="100" height="100" alt={'Rush Delivery'} />
                    </div>
                </figure>
                <Form onFinish={sendPasswordReset}>
                    <div className={'my-4'}>Para restablecer tu contrasena por favor ingresa tu email</div>
                    <Form.Item
                        style={{ marginBottom: '20px' }}
                        hasFeedback
                        rules={[{ required: true, message: 'Por favor ingresa tu email!' }]}
                    >
                        <Input
                            placeholder={'Email'}
                            onChange={event => setEmail(event.target.value)}
                            autoComplete={'email'}
                            value={email}
                        />
                    </Form.Item>

                    <Row justify="space-between">
                        <Button
                            loading={sendingRecoveryForm}
                            type="primary"
                            htmlType={'submit'}
                            onClick={sendPasswordReset}
                        >
                            Enviar
                        </Button>
                        <div style={{ color: '#c00', cursor: 'pointer' }} onClick={() => setResettingPassword(false)}>
                            Cancelar
                        </div>
                    </Row>
                </Form>
                ;
            </div>
        );
    }

    const LoginForm = (
        <>
            <div className="access_social">
                <FacebookLogin
                    style={{ width: '100%' }}
                    className="facebook-btn"
                    appId="280838179805343"
                    autoLoad={false}
                    fields="name,email,picture"
                    callback={facebookLogin}
                    isMobile={false}
                    render={(renderProps: any) => (
                        <a onClick={renderProps.onClick} className="social_bt facebook p-3 rounded">
                            <FacebookIcon />
                            <span className="ml-1 font-bold text-lg">Ingresar con Facebook</span>
                        </a>
                    )}
                />

                <GoogleLogin
                    className="google-btn"
                    style={{ width: '100%' }}
                    clientId="120497830262-unvm3n0sph9berg116kj7ocurj516tra.apps.googleusercontent.com"
                    buttonText="Ingresar con Google"
                    onSuccess={r => googleLogin(r)}
                    cookiePolicy={'single_host_origin'}
                    render={renderProps => (
                        <a href="#0" className="social_bt google p-3 rounded" onClick={renderProps.onClick}>
                            <GoogleIcon /> <span className="ml-1 font-bold text-lg">Ingresar con Google</span>
                        </a>
                    )}
                />
            </div>

            <Form onFinish={loginNow}>
                <Form.Item name="email" rules={[{ required: true, message: 'Requerido' }, { type: 'email' }]}>
                    <Input placeholder={'Email'} />
                </Form.Item>

                <Form.Item name="password" rules={[{ required: true, message: 'Requerido' }]}>
                    <Input.Password placeholder={'Contraseña'} />
                </Form.Item>

                <div className="clearfix add_bottom_15">
                    <div className="checkboxes float-left" />
                    <div className="float-right">
                        <a id="forgot" onClick={() => setResettingPassword(true)}>
                            Olvidaste tu constraseña?
                        </a>
                    </div>
                </div>
                <Button
                    style={{
                        backgroundColor: '#00b970',
                        color: '#fff',
                    }}
                    size="large"
                    htmlType={'submit'}
                    className="w-full  rounded font-bold text-lg"
                >
                    Ingresar
                </Button>
                <div className="text-center mt-2">
                    <small onClick={() => setAction('Register')}>
                        No tienes una cuenta?
                        <strong className="ml-1">
                            <a>Registrarse con email</a>
                        </strong>
                    </small>
                </div>
            </Form>
        </>
    );

    const RegisterForm = (
        <Form onFinish={signUp}>
            <div className="">
                <div className="-text">
                    <h4 className="font-bold text-lg text-center">Crear una Cuenta</h4>
                </div>

                <Form.Item name="first_name" rules={[{ required: true, message: 'Requerido' }]}>
                    <Input placeholder={'Nombres'} />
                </Form.Item>

                <Form.Item name="last_name" rules={[{ required: true, message: 'Requerido' }]}>
                    <Input placeholder={'Apellidos'} />
                </Form.Item>

                <Form.Item name="email" rules={[{ required: true, message: 'Requerido' }, { type: 'email' }]}>
                    <Input placeholder={'Email'} />
                </Form.Item>

                <Form.Item name="password" rules={[{ required: true, message: 'Requerido' }]}>
                    <Input.Password placeholder={'Contraseña'} />
                </Form.Item>

                <Form.Item
                    className="signup-checkbox text-left"
                    name="conditions"
                    rules={[{ required: true, message: 'Requerido' }]}
                    valuePropName={'checked'}
                >
                    <Checkbox
                        style={{
                            color: '#000',
                        }}
                    >
                        Al ingresar, aceptas nuestros <Link to="/terms-and-conditions"> Términos & Condiciones </Link> y{' '}
                        <Link to="/privacy-policy"> Políticas de Privacidad </Link>
                    </Checkbox>
                </Form.Item>

                <Form.Item>
                    <Button
                        style={{
                            backgroundColor: '#00b970',
                            color: '#fff',
                        }}
                        className="w-full rounded"
                        size="large"
                        loading={loading}
                        htmlType={'submit'}
                    >
                        Crear Cuenta
                    </Button>
                </Form.Item>

                <div className="text-center mt-2">
                    <small onClick={() => setAction('Login')}>
                        ya tienes una cuenta?
                        <strong className="ml-1">
                            <a>Ingresa con tu email</a>
                        </strong>
                    </small>
                </div>
            </div>
        </Form>
    );

    if (AuthenticationStore.isAuthenticated) {
        return <Redirect to={'/store/point-of-sale/'} />;
    }

    return (
        <div>
            <figure>
                <div
                    className={'my-2'}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <img className="rounded" src={Logo} width="100" height="100" alt={'Rush Delivery'} />
                </div>
            </figure>

            {action == 'Login' ? LoginForm : RegisterForm}
        </div>
    );
};
