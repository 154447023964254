import { Card } from 'antd';
import React from 'react';

const ProductCard = (props: { title: string; count: number; color: string }) => {
    return (
        <Card
            className={'card-item'}
            style={{
                borderLeftWidth: 10,
                borderLeftColor: props.color,
                minHeight: '120px',
                borderRadius: 10,
            }}
        >
            <div className={'flex flex-col justify-end'}>
                <div className={'mb-auto'}>
                    <div
                        style={{
                            fontWeight: 'bold',
                        }}
                    >
                        {props.title.length > 30 ? props.title.substring(0, 30) + '.' : props.title}
                    </div>
                    {/*<div>$7.50</div>*/}
                </div>
            </div>
        </Card>
    );
};

export default ProductCard;
