import { action, observable } from 'mobx';
import axios from 'axios';
import { notification } from 'antd';

class PrinterStore {
    @observable isPrinting = false;
    @observable server = '';
    @observable host = '';
    @observable port = 9100;

    constructor() {
        this.server = window.localStorage.getItem('server') ?? '127.0.0.1';
        this.host = window.localStorage.getItem('host') ?? '';
        this.port = parseInt(window.localStorage.getItem('port') ?? '9100');
    }

    @action setServer(server: string) {
        this.server = server;
        window.localStorage.setItem('server', server);
    }

    @action setHost(host: string) {
        this.host = host;
        window.localStorage.setItem('host', host);
    }

    @action setPort(port: number) {
        this.port = port;
        window.localStorage.setItem('port', port.toString());
    }

    @action printText = async (text: any) => {
        this.isPrinting = true;

        const url = `https://${this.server}:4101/print/`;

        await axios
            .post(
                url,
                {
                    host: this.host,
                    port: this.port,
                    text: text,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            )
            .catch(e => {
                notification.error({
                    key: 'print',
                    message: 'Error al imprimir, verifique la configuración de la impresora',
                    placement: 'bottomRight',
                    description: e.message,
                });
            });

        this.isPrinting = false;
    };
}

export default new PrinterStore();
