import React, { useEffect, useState } from 'react';
import { Drawer, notification, Spin } from 'antd';
import { isMobile } from 'react-device-detect';
import axios from 'axios';
import { OrderStore } from '../../../stores/OrderStore';

const FileDownload = require('js-file-download');

interface Props {
    invoice_id: string;
    handleClose: () => void;
}

const InvoicePreview = ({ invoice_id, handleClose }: Props) => {
    const [loading, setLoading] = useState(false);
    const [fileURL, setFileURL] = useState('');

    useEffect(() => {
        setLoading(true);
        const cancelRequest = axios.CancelToken.source();

        OrderStore.getRide(invoice_id, cancelRequest).then(async data => {
            const file = new Blob([data], { type: 'application/pdf' });

            const fileURL = URL.createObjectURL(file);

            setLoading(false);
            setFileURL(fileURL);
        });
    }, [invoice_id]);

    if (isMobile) {
        return null;
    }

    return (
        <Drawer title="Preview" width={'80%'} closable visible placement={'left'} onClose={handleClose}>
            <Spin spinning={loading}>
                <iframe
                    title={'Quote Preview'}
                    src={fileURL}
                    frameBorder="0"
                    height={window.innerHeight}
                    width="100%"
                />
            </Spin>
        </Drawer>
    );
};

export default InvoicePreview;
