import React from 'react';
import { Button, Form, Input, InputNumber, message } from 'antd';
import { observer } from 'mobx-react-lite';

interface ISettings {
    server: string;
    host: string;
    port: number;
    handleChange: (server: string, host: string, port: number) => void;
}

const Settings = (props: ISettings) => {
    const onFinish = (values: any) => {
        props.handleChange(values.server, values.host, values.port);
    };
    const onFinishFailed = (errorInfo: any) => {
        message.error(errorInfo.toString());
    };

    return (
        <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{
                server: props.server,
                host: props.host,
                port: props.port,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout={'vertical'}
        >
            <Form.Item label="Server Printer Ip" name="server" rules={[{ required: true, message: 'Requerido!' }]}>
                <Input />
            </Form.Item>

            <Form.Item label="Printer Ip" name="host" rules={[{ required: true, message: 'Requerido!' }]}>
                <Input />
            </Form.Item>

            <Form.Item label="Puerto" name="port" rules={[{ required: true, message: 'Requerido!' }]}>
                <InputNumber />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit">
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );
};

export default observer(Settings);
