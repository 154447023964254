import { action, computed, observable } from 'mobx';
import {
    ExtraListProps,
    LineItemProps,
    OrderProps,
    OwnerProps,
    ProductProps,
    UserProps,
} from '../../../types/types.ds';
import { message } from 'antd';

class LocalOrderStore {
    @observable loading = false;
    @observable IsPendingToSave = false;

    @observable order: OrderProps | null = null;

    @action setOrder(order: OrderProps | null) {
        this.order = order;
        this.recalculateTotals();
    }

    @action setIsPendingToSave(isPending: boolean) {
        this.IsPendingToSave = isPending;
    }

    @action updateOrderField<K extends keyof OrderProps, V extends OrderProps[K]>(key: K, value: V) {
        this.setIsPendingToSave(true);
        if (this.order != undefined) {
            this.order = {
                ...this.order,
                [key]: value,
            };
        }
        this.recalculateTotals();
    }

    @action recalculateTotals() {
        if (this.order != undefined) {
            this.order.total_without_tax = this.order.order_items.reduce(
                (t, item: LineItemProps) => t + Number(item.subtotal_without_tax),
                0
            );
            this.order.total_taxes = this.order.order_items.reduce((t, item) => t + Number(item.total_taxes), 0);

            this.order.total_with_taxes = this.order.order_items.reduce(
                (t, item) => t + Number(item.total_with_taxes),
                0
            );
        }
    }

    getUnitPrice(product: ProductProps, extras?: ExtraListProps[]) {
        const unit_price = product.price;

        if (extras) {
            const replace_unit_price = this.getAReplacePricingOption(extras);
            if (replace_unit_price) {
                console.log('replace unit price', replace_unit_price);
                return Number(replace_unit_price);
            }
            const total_extras = this.getAddedOptions(extras);
            console.log('total extras', total_extras);
            return Number(unit_price + total_extras);
        } else {
            console.log('no extras');
        }

        return Number(unit_price);
    }

    /**
     * Check if all required options were selected
     * @param extras_list
     */
    isCompletedItem(extras_list: ExtraListProps[]) {
        const isNotCompleted = extras_list.map(group => {
            const total_picked = group.options.filter(option => option.picked).length;

            if (group.min > 0 && group.min > total_picked) {
                return `${group.name}: Mínimo ${group.min} opciones`;
            }

            if (group.required && total_picked === 0) {
                return `${group.name} es requerido`;
            }

            return group.required && total_picked === 0;
        });
    }

    /**
     *  Calculate all extra prices with option "add"
     * @param extras_list
     */
    getAddedOptions(extras_list: ExtraListProps[]) {
        let extra_total = 0;
        extras_list
            ?.filter(extra => extra.pricing === 'add')
            ?.forEach(item => {
                extra_total += item.options
                    .filter(option => option.picked)
                    .reduce((total, option) => total + parseFloat(option.extra_price), 0);
            });

        return extra_total;
    }

    /**
     * Return a replace pricing if exits
     * @param extras_list
     * @return Number | null
     */
    getAReplacePricingOption(extras_list: ExtraListProps[]) {
        const pricing = extras_list?.find(extra => extra.pricing === 'replace') || null;

        if (pricing) {
            const option = pricing.options.find(option => option.picked);
            if (option) {
                return parseFloat(option.extra_price);
            }
        }

        return null;
    }
}

export default new LocalOrderStore();
