import { observer } from 'mobx-react';
import { LocalOrdersReportStore } from '../../models/ReportsStore';
import React from 'react';
import { Pie } from 'react-chartjs-2';

import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import { Statistic, Card } from 'antd';

ChartJS.register(ArcElement, Tooltip, Legend);

const ReportByDay = () => {
    const reportByDay = LocalOrdersReportStore.reportByDay;

    // const data = {
    //     labels: ['Consumidor Final', 'Cliente'],
    //     datasets: [
    //         {
    //             label: 'Total $',
    //             data: [reportByDay.final_consumer],
    //             // backgroundColor: reportByDay.best_selling_products.map(
    //             //     () =>
    //             //         `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(
    //             //             Math.random() * 255
    //             //         )}, 0.6)`
    //             // ),
    //             borderWidth: 0,
    //         },
    //     ],
    // };

    const data = {
        labels: ['Factura con Datos', 'Consumidor Final'],
        datasets: [
            {
                label: '# de Facturas',
                data: [reportByDay.customer_invoices.count, reportByDay.final_customer_invoices.count],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 2,
            },
        ],
    };

    const data2 = {
        labels: ['Factura con Datos', 'Consumidor Final'],
        datasets: [
            {
                label: 'Total de Facturas',
                data: [reportByDay.customer_invoices.total, reportByDay.final_customer_invoices.total],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 2,
            },
        ],
    };

    return (
        <div className={'flex flex-row justify-center'}>
            {/*<Pie data={data} />*/}
            {/*<Pie data={data2} />*/}
        </div>
    );
};

export default observer(ReportByDay);
