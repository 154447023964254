/**
 * Constants
 */

export const colors = ['#E0BBE4', '#957DAD', '#D291BC', '#FEC8D8', '#FFDFD3'];

export function* getNextColor() {
    for (const num of colors) {
        yield num;
    }
}
