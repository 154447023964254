import '../AppPOSLayout.less';

import React from 'react';
import { Layout, Menu } from 'antd';
import { Route, Switch } from 'react-router-dom';
import PointOfSales from '../pages/PointOfSales';
import TablesList from '../pages/PointOfSales/TablesList';
import Orders from '../pages/Orders/Orders';
import Authentication from '../pages/authentication/Authentication';
import OrderDetail from '../pages/Orders/OrderDetail';
import { UserOutlined, OrderedListOutlined, DesktopOutlined, SettingOutlined, IeOutlined } from '@ant-design/icons';
import { AuthenticationStore } from '../stores/AuthenticationStore';
import PageSettings from '../pages/PointOfSales/PageSettings';
import UserInfo from '../pages/PointOfSales/UserLogo';
import ReportByMonthPage from '../pages/PointOfSales/ReportByMonthPage';
import Invoices from '../pages/Invoices/Invoices';

import ReportByDayPage from '../pages/PointOfSales/ReportByDayPage';

const { Header, Content, Footer, Sider } = Layout;

const POSLayout = (props: any) => {
    const Items = [
        {
            key: 'Tables',
            icon: <DesktopOutlined />,
            label: 'Mesas',
            onClick: () => {
                props.history.push('/');
            },
        },
        {
            key: 'Orders',
            icon: <OrderedListOutlined />,
            label: 'Ventas',
            onClick: () => {
                props.history.push('/orders/');
            },
        },

        {
            key: 'Reportes',
            icon: <DesktopOutlined />,
            label: 'Reportes',
            children: [
                {
                    label: 'Ventas por Dia',
                    key: 'ByDay',
                    onClick: () => {
                        props.history.push('/reports/by-day/');
                    },
                },
                {
                    label: 'Ventas Por Mes',
                    key: 'ByMonth',
                    onClick: () => {
                        props.history.push('/reports/by-month/');
                    },
                },
            ],
        },

        {
            key: '3',
            icon: <SettingOutlined />,
            label: 'Configuraciones',
            path: '/settings',
            onClick: () => {
                props.history.push('/settings/');
            },
        },

        {
            key: 'Logout',
            icon: <UserOutlined />,
            label: 'Salir',
            onClick: () => {
                AuthenticationStore.logout();
            },
        },
    ];
    return (
        <Layout
            style={{
                height: '100vh',
            }}
        >
            <Sider
                breakpoint="xxl"
                // collapsedWidth="80"
                collapsedWidth="0"
                // collapsed={true}
                onBreakpoint={broken => {
                    console.log(broken);
                }}
                onCollapse={(collapsed, type) => {
                    console.log(collapsed, type);
                }}
            >
                <div className="logo">
                    <UserInfo />
                </div>
                <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']} items={Items} />
            </Sider>

            <Layout>
                <Layout.Content style={{ margin: '0' }}>
                    <div className={'body'} style={{ minHeight: '100vh', padding: 10 }}>
                        {props.children}
                        <Switch>
                            <Route path="/reports/by-month/" exact component={ReportByMonthPage} />
                            <Route path="/reports/by-day/" exact component={ReportByDayPage} />
                            <Route path="/" exact component={TablesList} />
                            <Route path="/table/:table_uid/" exact component={PointOfSales} />
                            <Route path="/orders/:uid/" exact component={OrderDetail} />
                            <Route path="/orders/" exact component={Orders} />
                            <Route path="/invoices/" exact component={Invoices} />
                            <Route path="/settings/" exact component={PageSettings} />
                            <Route path="/login/" exact component={Authentication} />
                        </Switch>
                    </div>
                </Layout.Content>
            </Layout>
        </Layout>
    );
};

export default POSLayout;
