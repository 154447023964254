import { observer } from 'mobx-react';
import { LocalOrdersReportStore } from '../../models/ReportsStore';
import React, { useEffect } from 'react';
import { AuthenticationStore } from '../../../../stores/AuthenticationStore';
import { UserStore } from '../../../../stores/UserStore';
import { Bar } from 'react-chartjs-2';
import { Chart } from 'react-chartjs-2';

import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
    Title,
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const labels = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
];

const ReportByMonth = () => {
    const reportByMonth = LocalOrdersReportStore.reportByMonth;

    const data = {
        labels,
        datasets: [
            {
                label: 'Total $',
                data: reportByMonth.map(item => item.sales),
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
            {
                label: 'Cantidad #',
                data: reportByMonth.map(item => item.count),
                backgroundColor: 'rgba(54, 162, 235, 0.5)',
            },
        ],
    };
    return (
        <Bar
            options={{
                responsive: true,
                plugins: {
                    legend: {
                        position: 'top' as const,
                    },
                    title: {
                        display: true,
                        text: 'Reporte de Ventas por Mes',
                    },
                },
            }}
            data={data}
        />
    );
};

export default observer(ReportByMonth);
