import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import { ILocalOrder, LocalOrderStore } from '../../stores/LocalOrderStore';
import { Button, Col, Divider, Modal, notification, Popover, Row, Spin, Table, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { PaginationComponent } from '../../components/pagination';
import {
    ArrowLeftOutlined,
    FilePdfOutlined,
    MailOutlined,
    PrinterOutlined,
    QuestionCircleOutlined,
} from '@ant-design/icons';
import InvoicePreview from '../PointOfSales/components/InvoicePreview';
import { OrderStore } from '../../stores/OrderStore';
import CustomerForm from '../PointOfSales/CustomerForm';
import { IInvoiceStatus } from '../../types/types.ds';
import OrderFilter from '../PointOfSales/components/OrderFilter/OrderFilter';
import InvoiceStore from '../PointOfSales/models/InvoiceStore';
import PrinterStore from '../../stores/PrinterStore';

import OrderStatusTag from '../PointOfSales/components/OrderStatusTag';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const Orders = () => {
    const history = useHistory();
    const parameters = useQuery();
    const [preview, setPreview] = useState<string | null>(null);
    const [editRecord, setEditRecord] = useState<any>();

    const StatusTag = ({ status }: { status: IInvoiceStatus }) => {
        switch (status) {
            case 'AUTORIZADO':
                return <Tag color="success">{status}</Tag>;
            case 'NO AUTORIZADO':
                return <Tag color="error">{status}</Tag>;
            case 'DEVUELTA':
                return <Tag color="error">{status}</Tag>;
            case 'RECIBIDA':
                return <Tag color="warning">{status}</Tag>;
            default:
                return <Tag color="#55acee">Pendiente</Tag>;
        }
    };
    const ButtonBill = ({ order }: { order: ILocalOrder }) => {
        const [isLoading, setIsLoading] = useState(false);
        return (
            <Button
                loading={isLoading}
                onClick={async () => {
                    setIsLoading(true);
                    await OrderStore.createInvoice(order.uid).catch(e => {
                        notification.error({
                            message: 'Error',
                            description: e.message,
                        });
                    });
                    setIsLoading(false);
                    loadOrders();
                }}
            >
                Facturar
            </Button>
        );
    };

    const ButtonValidate = ({ invoice_uid }: { invoice_uid: string }) => {
        const [isLoading, setIsLoading] = useState(false);
        return (
            <Button
                loading={isLoading}
                onClick={async () => {
                    setIsLoading(true);
                    await OrderStore.validateInvoice(invoice_uid).catch(e => {
                        notification.error({
                            message: 'Error',
                            description: e.message,
                        });
                    });
                    setIsLoading(false);
                    // loadOrders();
                }}
            >
                Enviar al SRI
            </Button>
        );
    };

    const ButtonAuthorize = ({ invoice_uid }: { invoice_uid: string }) => {
        const [isLoading, setIsLoading] = useState(false);
        return (
            <Button
                loading={isLoading}
                onClick={async () => {
                    setIsLoading(true);
                    const r = await OrderStore.authorizeInvoice(invoice_uid).catch(r => {
                        notification.success({
                            message: r,
                            description: 'Info',
                        });
                    });

                    setIsLoading(false);
                }}
            >
                Autorizar
            </Button>
        );
    };
    const ButtonSendBill = ({ invoice_uid }: { invoice_uid: string }) => {
        const [isLoading, setIsLoading] = useState(false);
        return (
            <Spin spinning={isLoading}>
                <MailOutlined
                    title="Enviar Factura al email del cliente"
                    style={{
                        fontSize: 33,
                    }}
                    // loading={isLoading}

                    onClick={async () => {
                        setIsLoading(true);
                        const r = await OrderStore.sendBillToCustomer(invoice_uid).catch(r => {
                            notification.success({
                                message: r,
                                description: 'Info',
                            });
                        });

                        setIsLoading(false);
                    }}
                />
            </Spin>
        );
    };

    const OrderEditForm = ({ order, onCancel }: { order: ILocalOrder; onCancel: () => void }) => {
        return (
            <Modal visible title={'Datos del Cliente'} onCancel={onCancel} footer={[]}>
                <CustomerForm
                    contact={{
                        identification: order?.identification_number ?? '',
                        billing_name: order?.billing_name,
                        identification_type: order?.identification_type ?? '',
                        email: order?.customer_email ?? '',
                        phone: order?.phone,
                        address: order?.shipping_street,
                    }}
                    handleChange={values => {
                        OrderStore.updateOrder(order.uid, {
                            billing_name: values.billing_name,
                            identification_number: values.identification,
                            identification_type: values.identification_type,
                            phone: values.phone,
                            shipping_street: values.address,
                            customer_email: values.email,
                        });
                        loadOrders();
                        onCancel();
                    }}
                />
            </Modal>
        );
    };
    const columns: ColumnsType<ILocalOrder> = [
        {
            key: 'id',
            title: 'id',
            dataIndex: 'id',
            render: (text, record) => (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <div>Nro. #{record.id} </div>
                    <div>Mesa# {record.table_name}</div>
                    <div>
                        <OrderStatusTag status={record.status} />
                    </div>
                </div>
            ),
        },
        {
            key: 'created_time',
            title: 'Fecha',
            dataIndex: 'created_time',
            width: 150,
            render: value => new Date(value).toLocaleString(),
        },
        {
            key: 'billing_name',
            title: 'Cliente',
            dataIndex: 'billing_name',
            width: 200,
            render: (value, record) => (
                <div>
                    <div>{value}</div>
                    <div>{record.identification_number ?? '---'}</div>
                    <div>{record.customer_email ?? ''}</div>
                </div>
            ),
        },
        {
            key: 'total_without_tax',
            title: 'Subtotal',
            dataIndex: 'total_without_tax',
            render: value => <div>{value.toFixed(2)}</div>,
        },
        {
            key: 'total_taxes',
            title: 'IVA',
            dataIndex: 'total_taxes',
            render: value => <div>{value.toFixed(2)}</div>,
        },
        {
            key: 'grand_total_store',
            title: 'Total',
            dataIndex: 'grand_total_store',
            render: value => <div>{value.toFixed(2)}</div>,
        },
        {
            key: 'invoices',
            title: 'Facturas',
            dataIndex: 'invoices',
            width: 250,
            render: (value, order) => {
                const invoices_render = order.invoices.map(invoice => (
                    <div key={invoice.uid}>
                        <div className={'flex flex-row items-center'}>
                            <div className={'flex flex-col items-start'}>
                                <div>{invoice.number}</div>
                                <div>
                                    <StatusTag status={invoice.status} />
                                </div>
                            </div>
                            <div className={'flex flex-row items-start justify-center ml-2'}>
                                {!invoice.status && <ButtonValidate invoice_uid={invoice.uid} />}
                                {invoice.status === 'RECIBIDA' && <ButtonAuthorize invoice_uid={invoice.uid} />}

                                {invoice.status === 'AUTORIZADO' && !invoice.sent && order.customer_email && (
                                    <ButtonSendBill invoice_uid={invoice.uid} />
                                )}
                                {invoice.status === 'AUTORIZADO' ||
                                    (invoice.status === 'RECIBIDA' && (
                                        <FilePdfOutlined
                                            title="Ver Factura en PDF"
                                            style={{
                                                fontSize: 30,
                                                marginLeft: 10,
                                            }}
                                            onClick={() => {
                                                setPreview(invoice.uid);
                                            }}
                                        />
                                    ))}
                                {invoice.status === 'AUTORIZADO' && (
                                    <PrinterOutlined
                                        title={'Imprimir el Ticket de Factura'}
                                        style={{
                                            fontSize: 30,
                                            marginLeft: 10,
                                        }}
                                        onClick={async () => {
                                            const text = await InvoiceStore.getInvoiceTicket(invoice.uid);
                                            await PrinterStore.printText(text);
                                        }}
                                    />
                                )}

                                <div>
                                    {['NO AUTORIZADO', 'DEVUELTA'].includes(invoice.status ?? '') && (
                                        <div className={'flex flex-row items-center'}>
                                            <Popover
                                                placement="left"
                                                title={'Respuesta del SRI'}
                                                content={
                                                    <div
                                                        style={{
                                                            maxWidth: 500,
                                                        }}
                                                    >
                                                        {invoice.sri_response ?? 'NO ENVIADA'}
                                                    </div>
                                                }
                                                trigger="hover"
                                            >
                                                <div className={'flex flex-row items-center'}>
                                                    <div className={'mx-1'}>
                                                        <QuestionCircleOutlined
                                                            style={{
                                                                color: 'red',
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </Popover>
                                            <ButtonBill order={order} />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                ));

                return (
                    <div className={'flex flex-col items-center'}>
                        {order.invoices.length > 0 ? invoices_render : null}
                    </div>
                );
            },
        },

        {
            key: 'actions',
            title: 'Acciones',
            dataIndex: 'actions',
            render: (value, order) => (
                <div className={'flex flex-col items-center'}>
                    <Link to={`/orders/${order.uid}/`}>Ver</Link>
                </div>
            ),
        },
    ];

    const loadOrders = () => {
        const params: any = {};
        console.log('parameters', params);

        if (parameters.get('search')) {
            params['search'] = parameters.get('search');
        }

        if (parameters.get('page')) {
            params['page'] = parameters.get('page');
        }

        if (parameters.get('created_time_min')) {
            params['created_time_min'] = parameters.get('created_time_min');
        }

        if (parameters.get('created_time_max')) {
            params['created_time_max'] = parameters.get('created_time_max');
        }

        if (parameters.get('display_only')) {
            if (parameters.get('display_only') === 'invoiced') {
                params['invoiced'] = true;
            } else if (parameters.get('display_only') === 'not_invoiced') {
                params['invoiced'] = false;
            }
        }

        if (parameters.get('is_pending_authorization')) {
            params['is_pending_authorization'] = 'AUTORIZADO';
        }

        LocalOrderStore.getOrders({
            ...params,
            type: 'on-site',
        });
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        loadOrders();
    }, [location.search]);

    return (
        <div
            style={{
                width: '100%',
                // margin: 10,
                padding: 10,
            }}
        >
            <ArrowLeftOutlined
                onClick={() => {
                    history.push('/');
                }}
                style={{
                    fontSize: 30,
                }}
            />

            <Helmet>
                <meta charSet="utf-8" />
                <title>Tus Órdenes</title>
            </Helmet>

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    margin: 'auto',
                }}
            >
                <div
                    className="row"
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <div style={{ textAlign: 'center' }}>
                        <h4 className={'text-xl'} style={{ fontWeight: 'bold', marginTop: 15 }}>
                            Tus Órdenes
                        </h4>
                    </div>

                    <Row>
                        <Col xs={24}>
                            <OrderFilter />
                        </Col>
                    </Row>

                    <Divider />
                    <Row>
                        <Col>
                            <div className={'flex flex-row justify-end'}>
                                {LocalOrderStore.total_orders} Ordenes encontradas
                            </div>
                        </Col>
                    </Row>

                    <Divider />

                    <Row>
                        <Col span={24}>
                            <Table<ILocalOrder>
                                style={{
                                    width: '100%',
                                }}
                                loading={LocalOrderStore.loading}
                                columns={columns}
                                dataSource={LocalOrderStore.orders}
                                scroll={{ x: 800, y: 1200 }}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24}>
                            <div
                                style={{
                                    marginTop: 20,
                                }}
                            >
                                {LocalOrderStore.total_orders > 0 && (
                                    <PaginationComponent pageSize={10} total={LocalOrderStore.total_orders} />
                                )}
                            </div>
                        </Col>
                    </Row>

                    {preview && <InvoicePreview invoice_id={preview} handleClose={() => setPreview(null)} />}

                    {!LocalOrderStore.loading && LocalOrderStore.orders.length == 0 && (
                        <div className={'m-4 text-thin text-center'}>
                            <div className="mt-4">No haz realizado una orden todavía</div>
                        </div>
                    )}

                    {editRecord && (
                        <OrderEditForm
                            order={editRecord}
                            onCancel={() => {
                                setEditRecord(null);
                            }}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default observer(Orders);
