import React from 'react';
import { Button, Form, Input, message, Select, notification } from 'antd';
import { observer } from 'mobx-react-lite';
import { get, post } from '../../utils/request';
import { IContact } from '../../types/types.ds';
import { verificarCedula, verificarRuc } from './validator';
interface ICustomerFormProps {
    contact: Partial<IContact> &
        Required<
            Pick<IContact, 'billing_name' | 'identification' | 'identification_type' | 'email' | 'phone' | 'address'>
        >;
    handleChange: (values: IContact) => void;
}

const CustomerForm = (props: ICustomerFormProps) => {
    const [form] = Form.useForm();

    const onFinish = (values: any) => {
        post(`/restaurant/contacts/`, {
            billing_name: values.billing_name,
            identification: values.identification,
            identification_type: values.identification_type,
            email: values.email,
            phone: values.phone,
            address: values.address,
        });
        props.handleChange(values);
    };
    const onFinishFailed = (errorInfo: any) => {
        message.error('Por favor, complete todos los campos');
    };

    const handleSearchContact = (value: any) => {
        get(`/restaurant/contacts/`, { search: value }).then(r => {
            if (r.data.results.length > 0) {
                const contact: IContact = r.data.results[0];
                message.success(`Se encontró un contacto con el nombre ${contact.billing_name}`);
                form.setFieldsValue({
                    billing_name: contact.billing_name,
                    identification: contact.identification,
                    identification_type: contact.identification_type,
                    email: contact.email,
                    phone: contact.phone,
                    address: contact.address,
                });
            }
        });
    };

    return (
        <Form
            name="basic"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            initialValues={{
                identification: props.contact.identification,
                billing_name: props.contact.billing_name,
                identification_type: props.contact.identification_type,
                email: props.contact.email,
                phone: props.contact.phone,
                address: props.contact.address,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout={'vertical'}
            form={form}
        >
            <Form.Item
                label="Cedula o RUC"
                name="identification"
                rules={[
                    {
                        required: true,
                        message: 'Por favor, ingrese la cedula o RUC',
                    },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (getFieldValue('identification_type') == '05' && !verificarCedula(value)) {
                                console.log('entro');
                                return Promise.reject(new Error('Cedula inválido'));
                            }
                            if (getFieldValue('identification_type') == '04' && !verificarRuc(value)) {
                                console.log('entro');
                                return Promise.reject(new Error('Cedula inválido'));
                            }
                            return Promise.resolve();
                        },
                    }),
                ]}
            >
                <Input
                    onPressEnter={v => {
                        handleSearchContact(v.currentTarget.value);
                    }}
                />
            </Form.Item>

            <Form.Item
                label="Tipo de Identificacion"
                name="identification_type"
                rules={[
                    {
                        required: true,
                        message: 'Ingrese el tipo de identificacion',
                    },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (value === '04' && getFieldValue('identification').length !== 13) {
                                return Promise.reject('El RUC debe tener 13 digitos');
                            }
                            if (value === '05' && getFieldValue('identification').length !== 10) {
                                return Promise.reject('La cedula debe tener 10 digitos');
                            }
                            if (value === '07' && getFieldValue('identification') !== '9999999999999') {
                                return Promise.reject('El consumidor final debe ser 9999999999999');
                            }

                            return Promise.resolve();
                        },
                    }),
                ]}
            >
                <Select>
                    <Select.Option value="04">RUC</Select.Option>
                    <Select.Option value="05">CEDULA</Select.Option>
                    <Select.Option value="06">PASAPORTE</Select.Option>
                    <Select.Option value="07">CONSUMIDOR_FINAL</Select.Option>
                    <Select.Option value="08">IDENTIFICACION_EXTERIOR</Select.Option>
                </Select>
            </Form.Item>

            <Form.Item label="Razon Social" name="billing_name" rules={[{ required: true, message: 'Requerido!' }]}>
                <Input />
            </Form.Item>

            <Form.Item
                label="Correo Electrónico"
                name="email"
                rules={[
                    { required: true, message: 'Reuquerido!' },
                    {
                        type: 'email',
                        message: 'El correo electrónico no es válido!',
                    },
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item label="Teléfono" name="phone" rules={[{ required: true, message: 'Reuquerido!' }]}>
                <Input />
            </Form.Item>

            <Form.Item label="Dirección" name="address" rules={[{ required: true, message: 'Reuquerido!' }]}>
                <Input.TextArea />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button
                    type="primary"
                    onClick={() => {
                        form.submit();
                    }}
                >
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );
};

export default observer(CustomerForm);
