import { Card } from 'antd';
import React from 'react';
import { CloseCircleOutlined } from '@ant-design/icons';
import { LineItemProps } from '../../../../../types/types.ds';
import OptionCard from './OptionCard';

interface IOrderItemCard {
    isSelected: boolean;
    order_item: LineItemProps;
    handleClickItem: () => void;
    handleClickRemoveItem: () => void;
}
const OrderItemCard = (props: IOrderItemCard) => {
    const { isSelected, order_item, handleClickItem, handleClickRemoveItem } = props;

    const primary = isSelected ? 'border-primary-color' : '';
    return (
        <Card
            className={`card-item cursor-default ${primary}`}
            style={{
                minHeight: '40px',
                borderRadius: 10,
                margin: 2,
            }}
            bodyStyle={
                {
                    // padding: 5,
                    // margin: 0,
                }
            }
            onClick={handleClickItem}
        >
            <div className={'flex flex-row justify-between'}>
                <div className={'flex flex-row items-center justify-center wrapper-cart-quantity'}>
                    <div
                        style={{
                            backgroundColor: 'white',
                            borderRadius: '50%',
                            width: 20,
                            height: 20,
                            color: '#000',
                            textAlign: 'center',
                            fontSize: 12,
                            fontWeight: 'bold',
                        }}
                    >
                        {props.order_item.quantity}
                    </div>
                </div>
                <div className={'flex flex-col justify-start ml-2 wrapper-cart-product-name'}>
                    <div
                        style={{
                            fontWeight: 'bold',
                            maxWidth: '85%',
                        }}
                    >
                        {order_item.product_name}
                    </div>
                    <div className={'flex flex-col'}>
                        {order_item.extras?.map(extra => (
                            <div key={extra.uid} className={'flex flex-row item-center'}>
                                <div className={'font-bold'}>{extra.name}:</div>
                                <div className={'flex flex-row ml-1 '}>
                                    {extra.required && extra.options.filter(o => o.picked).length == 0 && (
                                        <div
                                            style={{
                                                color: 'red',
                                            }}
                                        >
                                            pendiente
                                        </div>
                                    )}
                                    {extra.options
                                        .filter(option => option.picked)
                                        .map(option => (
                                            <div key={option.uid}>
                                                <OptionCard option={option} />
                                            </div>
                                        ))}
                                </div>
                            </div>
                        ))}
                        {order_item.special_instructions ?? (
                            <div className={'font-bold'}>Notes: {order_item.special_instructions}</div>
                        )}
                    </div>
                </div>
                <div className={'flex flex-col items-center justify-center wrapper-cart-product-price'}>
                    <CloseCircleOutlined
                        className={'cursor-pointer'}
                        onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleClickRemoveItem();
                        }}
                        style={{
                            color: 'white',
                            fontWeight: 'bold',
                            position: 'absolute',
                            top: -3,
                            right: 0,
                            fontSize: 20,
                        }}
                    />
                    <div className={'mr-2 mt-1'}>${order_item.subtotal_without_tax.toFixed(2)}</div>
                </div>
            </div>
        </Card>
    );
};

export default OrderItemCard;
