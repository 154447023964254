import { action, observable, runInAction } from 'mobx';
import { CancelTokenSource } from 'axios';
import { get } from '../../../utils/request';
import { CategoryProps, OwnerProps, ProductProps, RestaurantTablesProps } from '../../../types/types.ds';
import { OrderStore } from '../../../stores/OrderStore';

export interface IReportByDay {
    sales: Invoices;
    invoiced: Invoices;
    final_customer_invoices: Invoices;
    customer_invoices: Invoices;
    error_invoices: Invoices;
}

export interface BestSellingProduct {
    product_name: string;
    quantity: number;
    total: number;
}

export interface Invoices {
    total: number;
    count: number;
}

class Report {
    @observable loading = false;
    @observable reportByMonth: Array<{
        month: number;
        sales: number;
        count: number;
    }> = [];
    @observable reportByDay: IReportByDay = {
        sales: {
            total: 0,
            count: 0,
        },
        invoiced: {
            total: 0,
            count: 0,
        },
        final_customer_invoices: {
            total: 0,
            count: 0,
        },
        customer_invoices: {
            total: 0,
            count: 0,
        },
        error_invoices: {
            total: 0,
            count: 0,
        },
    };

    @action
    async getReportByMonth(year: string, cancelRequest?: CancelTokenSource) {
        this.loading = true;
        const response = await get(`/restaurant/local-orders/sales-by-month/`, {
            year: year,
        });
        this.reportByMonth = response.data;
        this.loading = false;
    }

    @action
    async getReportByDay(start_date: string, end_date: string, cancelRequest?: CancelTokenSource) {
        this.loading = true;
        const response = await get(`/restaurant/local-orders/sales-by-day/`, {
            start_date: start_date,
            end_date: end_date,
        });
        this.reportByDay = response.data;
        this.loading = false;
    }
}

export const LocalOrdersReportStore = new Report();
