import React, { useEffect, useState } from 'react';

import { observer } from 'mobx-react';
import { Image } from 'antd';
import { UserStore } from '../../stores/UserStore';

const UserInfo = () => {
    return (
        <div>
            <Image src={UserStore.user?.thumbnail} preview={false} width={'100%'} height={'auto'} />
        </div>
    );
};
export default observer(UserInfo);
