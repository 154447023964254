import React from 'react';
import { Button, Col, Space } from 'antd';
interface Props {
    selected: boolean;
    occupied: boolean;
    height?: number;
}

export const TableItem: React.FC<Props> = ({ selected, occupied, height = 110, children }) => {
    return (
        <Col xs={24} sm={8} md={6} lg={4} xl={3} xxl={3}>
            <div
                style={{
                    width: '100%',
                    padding: 5,
                }}
            >
                <div
                    style={{
                        width: '100%',
                        borderRightColor: '#fff',
                        borderRadius: 10,
                        // borderWidth: 1,
                        height: height,
                        borderColor: selected ? 'green' : '#d4d4d4',
                        backgroundColor: occupied ? '#DFFF00' : '#d4d4d4',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: 10,
                    }}
                >
                    {children}
                </div>
            </div>
        </Col>
    );
};
