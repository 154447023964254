import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { BrowserRouter, Redirect, Route, RouteProps, Switch, useHistory } from 'react-router-dom';
import { UserStore } from './stores/UserStore';
import { Loading } from './components/loading/loading';
import { notification } from 'antd';
import { AuthenticationStore } from './stores/AuthenticationStore';
import { NotificationStore } from './stores/NotificationStore';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { getAnalytics, logEvent } from 'firebase/analytics';
import POSLayout from './layouts/POSLayout';
import { FourOhFour, Home, PrivacyPolicy, TermsAndConditions } from './pages';
import Authentication from './pages/authentication/Authentication';
import MainLayout from './layouts/MainLayout';
import { POSCompanyStore } from './pages/PointOfSales/models/POSCompanyStore';
import useUserSocketConnection from './services/useUserSocketConnection';

const PrivateRoute = observer(({ component: ChildComponent, ...restProps }: RouteProps) => {
    return (
        <Route
            {...restProps}
            render={props => {
                if (!AuthenticationStore.isAuthenticated) {
                    const next = window.location.pathname;
                    return <Redirect to={'/login?next=' + next} />;
                } else {
                    //@ts-ignore
                    return <ChildComponent {...props} />;
                }
            }}
        />
    );
});

const PushNotificationService = (props: { user_uid: string; token: string }) => {
    const { new_notification } = useUserSocketConnection(props.user_uid, props.token);
    console.log('wtf');

    useEffect(() => {
        if (new_notification?.type == 'TABLE-UPDATED') {
            const table_updated = new_notification.payload;
            POSCompanyStore.updateLocalTables(table_updated);
        }
        if (new_notification?.type == 'ORDER-UPDATED') {
            // const order_updated = new_notification.payload;
            // POSCompanyStore.setTable(table_updated);
        }
    }, [new_notification]);

    return null;
};

const App = observer(() => {
    const firebaseConfig = {
        apiKey: 'AIzaSyDHd-3YRLFpm6zkNm6WglQjDgdHElcenqY',
        authDomain: 'jm-services-ab0d9.firebaseapp.com',
        databaseURL: 'https://jm-services-ab0d9.firebaseio.com',
        projectId: 'jm-services-ab0d9',
        storageBucket: 'jm-services-ab0d9.appspot.com',
        messagingSenderId: '120497830262',
        appId: '1:120497830262:web:e6fa30d4c9c9c6e5ffa257',
        measurementId: 'G-3QQ3CJPS1E',
    };

    const hasNotificationsSupport = 'Notification' in window;

    const app = initializeApp(firebaseConfig);
    const messaging = getMessaging(app);

    const analytics = getAnalytics(app);

    const [loading, setLoading] = React.useState(false);
    const history = useHistory();

    React.useEffect(() => {
        if (process.env.NODE_ENV === 'production') {
            logEvent(analytics, 'screen_view', {
                firebase_screen: window.location.pathname + window.location.search,
                firebase_screen_class: window.location.search,
            });
        }
    }, [history?.location]);

    React.useEffect(() => {
        setLoading(true);
        UserStore.loadCurrentUser()
            .then(() => {
                // NotificationStore.getNotifications();
            })
            .finally(() => setLoading(false));
    }, []);

    onMessage(messaging, payload => {
        if (payload.notification?.title) {
            notification.success({
                description: payload.notification.body,
                message: payload.notification.title,
                duration: 15,
            });

            // NotificationStore.getNotifications();
            // ChatStore.getRooms();
            // OrderStore.getOrders();

            new Notification(payload.notification.title, {
                body: payload.notification.body,
                silent: false,
            });
        }
    });

    /**
     * Get FCM token
     */
    React.useEffect(() => {
        if (hasNotificationsSupport && AuthenticationStore.isAuthenticated) {
            getToken(messaging, {
                vapidKey: 'BF6yxeTxOGFumJSoNWLgG5Y3tPoKCF64MbStALLJokA_Ih8rdbK_7qvx7bxB66IEXZcfgeg30517dFMQbHyHeg4',
            }).then(currentToken => {
                if (currentToken) {
                    UserStore.setPushNotificationToken(currentToken);
                }
            });
        }
    }, [AuthenticationStore.token]);

    useEffect(() => {
        if (UserStore.user) {
            POSCompanyStore.getSubcategoriesByCompany(UserStore.user.uid);

            POSCompanyStore.getProducts({
                owner: UserStore.user.uid,
                in_store: true,
            });
        }
    }, [UserStore.user?.uid]);

    React.useEffect(() => {
        if (AuthenticationStore.isAuthenticated) {
            UserStore.savePushNotificationToken();

            // NotificationStore.getNotifications();
            // OrderStore.getOrders();
            // ChatStore.getRooms();
        }
    }, [AuthenticationStore.token, UserStore.push_notification_token]);

    if (loading) {
        return <Loading />;
    }

    const used_id = UserStore.user?.uid;
    const user_token = AuthenticationStore.token;

    return (
        <React.Fragment>
            <BrowserRouter>
                <Switch>
                    <Route path="/" component={POSLayout} />

                    {/*<Route path="/">*/}
                    {/*    <MainLayout message={''} />*/}
                    {/*</Route>*/}

                    {/*<Route component={Not} />*/}
                </Switch>
            </BrowserRouter>

            {used_id && user_token && <PushNotificationService user_uid={used_id} token={user_token} />}
        </React.Fragment>
    );
});

export default App;
