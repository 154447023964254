import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { UserStore } from '../../stores/UserStore';
import { AuthenticationStore } from '../../stores/AuthenticationStore';
import { Redirect } from 'react-router-dom';
import { Row, Select, DatePicker, Card, Col, Statistic, Divider } from 'antd';
import { LocalOrdersReportStore } from './models/ReportsStore';
import { Loading } from '../../components/loading/loading';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import ReportByDay from './components/Charts/ReportByDay';
import DateRangePicker from '../../components/DateRangePicker';

const ReportByDayPage = () => {
    if (!AuthenticationStore.isAuthenticated) {
        return <Redirect to={'/login/'} />;
    }

    if (!UserStore.user) {
        return null;
    }

    const reportByDay = LocalOrdersReportStore.reportByDay;

    return (
        <div
            style={{
                width: '100%',
                padding: 30,
            }}
        >
            <Row>
                <Col span={24}>
                    <div className={'m-auto py-2 text-center'}>
                        <DateRangePicker
                            style={{ width: 400 }}
                            onChange={v => {
                                if (v != null) {
                                    const start_date = v[0] ? v[0].format('YYYY-MM-DD') : null;
                                    const end_date = v[1] ? v[1].format('YYYY-MM-DD') : null;

                                    if (start_date && end_date)
                                        LocalOrdersReportStore.getReportByDay(start_date, end_date);
                                }
                            }}
                        />
                    </div>
                </Col>
            </Row>

            {LocalOrdersReportStore.loading ? <Loading /> : null}

            <Row>
                <Col span={12}>
                    <Card>
                        <Statistic
                            title={`${reportByDay.sales.count} Órdenes `}
                            value={reportByDay.sales.total ?? 0}
                            precision={2}
                            valueStyle={{ color: '#3f8600' }}
                            // prefix={<ArrowUpOutlined />}
                            suffix="$"
                        />
                    </Card>
                </Col>
                <Col span={12}>
                    <Card>
                        <Statistic
                            title={`${reportByDay.invoiced.count} Facturas `}
                            value={reportByDay.invoiced.total ?? 0}
                            precision={2}
                            valueStyle={{ color: '#3f8600' }}
                            // prefix={<ArrowUpOutlined />}
                            suffix="$"
                        />
                    </Card>
                </Col>
            </Row>

            <Divider />

            <Row>
                <Col span={12}>
                    <Card title={`Consumidor Final`}>
                        <Statistic
                            title={`Cantidad`}
                            value={reportByDay.final_customer_invoices.count ?? 0}
                            precision={0}
                            valueStyle={{ color: '#3f8600' }}
                            // prefix={<ArrowUpOutlined />}
                            suffix=""
                        />
                        <Statistic
                            title={`Total`}
                            value={reportByDay.final_customer_invoices.total ?? 0}
                            precision={2}
                            valueStyle={{ color: '#3f8600' }}
                            // prefix={<ArrowUpOutlined />}
                            suffix="$"
                        />
                    </Card>
                </Col>

                <Col span={12}>
                    <Card title={'Factura Con Datos'}>
                        <Statistic
                            title={`Cantidad`}
                            value={reportByDay.customer_invoices.count ?? 0}
                            precision={0}
                            valueStyle={{ color: '#3f8600' }}
                            // prefix={<ArrowUpOutlined />}
                            suffix=""
                        />
                        <Statistic
                            title={`Total`}
                            value={reportByDay.customer_invoices.total ?? 0}
                            precision={2}
                            valueStyle={{ color: '#3f8600' }}
                            // prefix={<ArrowUpOutlined />}
                            suffix="$"
                        />
                    </Card>
                </Col>
            </Row>

            <Divider />

            <Row>
                <Col span={24}>
                    <Card title={'Facturas pendiente Autorización'}>
                        <Statistic
                            title={`Cantidad`}
                            value={reportByDay.error_invoices.count ?? 0}
                            precision={0}
                            valueStyle={{ color: '#3f8600' }}
                            // prefix={<ArrowUpOutlined />}
                            suffix=""
                        />
                        <Statistic
                            title={`Total`}
                            value={reportByDay.error_invoices.total ?? 0}
                            precision={2}
                            valueStyle={{ color: '#3f8600' }}
                            // prefix={<ArrowUpOutlined />}
                            suffix="$"
                        />
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <div
                        style={{
                            marginTop: 30,
                        }}
                    >
                        {LocalOrdersReportStore.loading ? <Loading /> : <ReportByDay />}
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default observer(ReportByDayPage);
