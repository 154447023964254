import { action, computed, observable } from 'mobx';
import {
    ExtraListProps,
    LineItemProps,
    OrderProps,
    OwnerProps,
    ProductProps,
    UserProps,
    IContact,
} from '../../../types/types.ds';

import { message } from 'antd';
import { get, post, put } from '../../../utils/request';

class InvoiceStore {
    @observable loading = false;

    @action
    async getInvoiceTicket(uid: string) {
        this.loading = true;
        const r = await get(`/restaurant/invoices/${uid}/ticket/`);
        this.loading = false;
        return r.data;
    }
}

export default new InvoiceStore();
