import React from 'react';

import { Button, Row, Col } from 'antd';

interface KeyBoardProps {
    onKeyClick: (key: string) => void;
}

const ButtonStyle = {
    width: '100%',
    height: '100%',
    fontSize: '1.5rem',
    fontWeight: 'bold',
    borderRadius: '0',
    border: '1px solid #e8e8e8',
    backgroundColor: '#fff',
    color: '#000',
    boxShadow: '0 0 0 0',
    transition: 'all 0.3s',
};

const KeyBoard: React.FC<KeyBoardProps> = ({ onKeyClick }) => {
    return (
        <div className="w-full">
            <Row className="key-board-row">
                <Col span={8}>
                    <Button style={ButtonStyle} onClick={() => onKeyClick('7')}>
                        7
                    </Button>
                </Col>
                <Col span={8}>
                    <Button style={ButtonStyle} onClick={() => onKeyClick('8')}>
                        8
                    </Button>
                </Col>
                <Col span={8}>
                    <Button style={ButtonStyle} onClick={() => onKeyClick('9')}>
                        9
                    </Button>
                </Col>
            </Row>
            <Row className="key-board-row">
                <Col span={8}>
                    <Button style={ButtonStyle} onClick={() => onKeyClick('4')}>
                        4
                    </Button>
                </Col>
                <Col span={8}>
                    <Button style={ButtonStyle} onClick={() => onKeyClick('5')}>
                        5
                    </Button>
                </Col>
                <Col span={8}>
                    <Button style={ButtonStyle} onClick={() => onKeyClick('6')}>
                        6
                    </Button>
                </Col>
            </Row>

            <Row className="key-board-row">
                <Col span={8}>
                    <Button style={ButtonStyle} onClick={() => onKeyClick('1')}>
                        1
                    </Button>
                </Col>
                <Col span={8}>
                    <Button style={ButtonStyle} onClick={() => onKeyClick('2')}>
                        2
                    </Button>
                </Col>
                <Col span={8}>
                    <Button style={ButtonStyle} onClick={() => onKeyClick('3')}>
                        3
                    </Button>
                </Col>
            </Row>

            <Row className="key-board-row">
                <Col span={8}>
                    <Button style={ButtonStyle} onClick={() => onKeyClick('←')}>
                        ←
                    </Button>
                </Col>

                <Col span={8}>
                    <Button style={ButtonStyle} onClick={() => onKeyClick('0')}>
                        0
                    </Button>
                </Col>
                <Col span={8}>
                    <Button style={ButtonStyle} onClick={() => onKeyClick('ok')}>
                        ok
                    </Button>
                </Col>
            </Row>
        </div>
    );
};

export default KeyBoard;
