import { action, observable, runInAction } from 'mobx';
import { CancelTokenSource } from 'axios';
import { get } from '../../../utils/request';
import { CategoryProps, OwnerProps, ProductProps, RestaurantTablesProps } from '../../../types/types.ds';
import { OrderStore } from '../../../stores/OrderStore';

class Company {
    @observable loading = true;
    @observable isLoadingTable = true;
    @observable categories: Array<CategoryProps> = [];
    @observable company?: OwnerProps;

    @observable products: ProductProps[] = [];
    @observable tables: RestaurantTablesProps[] = [];
    @observable table: RestaurantTablesProps | null = null;

    @action
    async getCompanyById(uid: string, cancelRequest: CancelTokenSource) {
        this.loading = true;
        const response = await get(`/companies/${uid}/`, cancelRequest);
        this.company = response.data;
        this.loading = false;
    }

    @action
    async getSubcategoriesByCompany(uid: string, cancelRequest?: CancelTokenSource) {
        const params = {
            owner: uid,
        };
        const res = await get(`/companies/${uid}/subcategories/`, params, cancelRequest);

        this.categories = res.data;
    }

    @action
    async getProducts(params: any) {
        const payload = {
            ...params,
        };

        await get('/products/', payload)
            .then(response => {
                runInAction(() => {
                    this.products = response.data.results.map((p: ProductProps) => {
                        return {
                            ...p,
                            price: p.local_price,
                            extras_list: p.extras_list.map(o => {
                                return {
                                    ...o,
                                    options: o.options.map(o => {
                                        return {
                                            ...o,
                                            extra_price: o.local_price,
                                        };
                                    }),
                                };
                            }),
                        };
                    });
                });
            })

            .finally(() => (this.loading = false));
    }

    @action async getTables(cancelRequest?: CancelTokenSource) {
        await get(`/restaurant/tables/`, {}, cancelRequest).then(r => {
            this.tables = r.data.results;
        });
    }

    @action async getTableById(table_id: string, cancelRequest: CancelTokenSource) {
        this.isLoadingTable = true;
        const r = await get(`/restaurant/tables/${table_id}/`, {}, cancelRequest);
        this.table = r.data;
        this.isLoadingTable = false;
    }

    @action updateLocalTables(table: RestaurantTablesProps | null) {
        if (table?.uid == this.table?.uid) {
            this.table = table;
        }
        //    Update table in the list
        this.tables = this.tables.map(c => {
            if (c.uid == table?.uid) {
                return {
                    ...table,
                };
            }
            return {
                ...c,
            };
        });
    }

    @action setTable(table: RestaurantTablesProps | null) {
        this.table = table;
        this.updateLocalTables(table);
    }

    @action async completeOrder(t: RestaurantTablesProps) {
        const order_uid = t.current_order?.uid ?? '';
        POSCompanyStore.setTable({
            ...t,
            current_order: null,
        });
        await OrderStore.updateOrder(order_uid, {
            status: 'delivered',
        })
            .then(r => {})
            .catch((error: any) => {
                const message = JSON.stringify(error?.response?.data);
                //@ts-ignore
                alert(message);
            })
            .finally(() => {});
    }
}

export const POSCompanyStore = new Company();
