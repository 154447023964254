import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useHistory, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { ArrowLeftOutlined } from '@ant-design/icons';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const Invoices = () => {
    const history = useHistory();
    const parameters = useQuery();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.search]);

    return (
        <div
            style={{
                width: '100%',
                margin: 10,
            }}
        >
            <ArrowLeftOutlined
                onClick={() => {
                    history.push('/');
                }}
                style={{
                    fontSize: 30,
                }}
            />

            <Helmet>
                <meta charSet="utf-8" />
                <title>Tus Facturas</title>
            </Helmet>
        </div>
    );
};

export default observer(Invoices);
