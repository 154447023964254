import React, { useEffect } from 'react';
import { UserStore } from '../../stores/UserStore';
import { observer } from 'mobx-react';
import PointOfSalesForm from './PointOfSaleForm';
import { Loading } from '../../components/loading/loading';
import { POSCompanyStore } from './models/POSCompanyStore';

const PointOfSalePage = () => {
    if (!UserStore.user) {
        return <Loading />;
    }
    return <PointOfSalesForm user={UserStore.user} />;
};

export default observer(PointOfSalePage);
