import { Card } from 'antd';
import { CoffeeOutlined } from '@ant-design/icons';
import React from 'react';

const CategoryCard = (props: { title: string; count: number; color: string; isSelected: boolean }) => {
    const primary = props.isSelected ? 'background-primary-color' : '';

    return (
        <Card
            className={`card-item ${primary}`}
            style={{
                backgroundColor: props.color,
                minHeight: 30,
                borderRadius: 10,
            }}
        >
            <div className={'flex flex-col justify-end'}>
                {/*<div>*/}
                {/*    <CoffeeOutlined*/}
                {/*        style={{*/}
                {/*            color: '#000',*/}
                {/*            fontSize: 16,*/}
                {/*        }}*/}
                {/*    />*/}
                {/*</div>*/}
                <div className={'mb-auto'}>
                    <div
                        className={'text-black'}
                        style={{
                            fontWeight: 'bold',
                            flexWrap: 'wrap',
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                            overflowX: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            userSelect: 'none',
                        }}
                    >
                        {props.title}
                    </div>
                    {/*<div className={'text-gray-700'}>{props.count} Items</div>*/}
                </div>
            </div>
        </Card>
    );
};

export default CategoryCard;
