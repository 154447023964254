import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';

import { OrderStore } from '../../stores/OrderStore';
import OrderCard from '../../components/cards/OrderCard';
import { Alert, Button, message, Modal, PageHeader } from 'antd';
import { Link, Skeleton } from '@mui/material';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { OrderProps, UserProps } from '../../types/types.ds';
import ContactCard from '../../components/cards/ContactCard';
import { Chat } from '../../components/Chat/Chat';
import { RoomProps } from '../../stores/ChatStore';
import { UserStore } from '../../stores/UserStore';
import { Helmet } from 'react-helmet';
import { Loading } from '../../components/loading/loading';
import OrderForm from '../PointOfSales/OrderForm';
import { POSCompanyStore } from '../PointOfSales/models/POSCompanyStore';

const OrderDetail = () => {
    const history = useHistory();
    const [isLoading, setLoading] = useState<boolean>(true);

    const { uid } = useParams<{ uid: string }>();

    const order = OrderStore.selected;

    useEffect(() => {
        // window.scrollTo(0, 0);
        setLoading(true);
        OrderStore.getOrder(uid).finally(() => {
            setLoading(false);
        });
    }, [uid]);

    useEffect(() => {
        if (order?.uid) {
            // POSCompanyStore.getTableById(order, cancelRequest1).then(res => {
            //     setIsLoading(false);
            // });
        }
    }, [order?.uid]);

    if (isLoading) {
        return <Loading />;
    }

    if (!order) {
        return <Loading />;
    }

    return (
        <div>
            <OrderForm order={order} table={'' as any} />
        </div>
    );
};

export default observer(OrderDetail);
