/**
 * Food Delivery - React Native Template
 *
 * @format
 * @flow
 */

import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { UserStore } from '../../stores/UserStore';
import { AuthenticationStore } from '../../stores/AuthenticationStore';
import { Redirect } from 'react-router-dom';
import { Row, Select } from 'antd';
import ReportByMonth from './components/Charts/ReportByMonth';
import { LocalOrdersReportStore } from './models/ReportsStore';
import { Loading } from '../../components/loading/loading';

const ReportByMonthPage = () => {
    if (!AuthenticationStore.isAuthenticated) {
        return <Redirect to={'/login/'} />;
    }

    if (!UserStore.user) {
        return null;
    }

    return (
        <div>
            <div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Row
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginTop: 10,
                        }}
                    >
                        <Select
                            showSearch
                            loading={LocalOrdersReportStore.loading}
                            placeholder="Selecciona un año"
                            optionFilterProp="children"
                            onChange={year => {
                                LocalOrdersReportStore.getReportByMonth(year);
                            }}
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            options={[
                                {
                                    value: '2023',
                                    label: '2023',
                                },
                                {
                                    value: '2022',
                                    label: '2022',
                                },
                            ]}
                        />
                        {LocalOrdersReportStore.loading ? <Loading /> : <ReportByMonth />}
                    </Row>
                </div>
            </div>
        </div>
    );
};

export default observer(ReportByMonthPage);
