import { DatePicker as ANTDatePicket } from 'antd';
import React from 'react';
import { RangePickerProps as BaseRangePickerProps } from 'antd/lib/date-picker/generatePicker';
import moment from 'moment';
import { ConfigProvider } from 'antd';

import es_ES from 'antd/es/locale/es_ES';

moment.locale('es-ES', {
    week: {
        dow: 1,
    },
});

const DateRangePicker = (props: BaseRangePickerProps<moment.Moment>) => {
    return (
        <ConfigProvider locale={es_ES}>
            <ANTDatePicket.RangePicker
                ranges={{
                    Hoy: [moment(), moment()],
                    Ayer: [moment().startOf('day').subtract(1, 'day'), moment().endOf('day').subtract(1, 'day')],
                    'Esta semana': [moment().startOf('week'), moment().endOf('week')],
                    // 'La semana anterior': [
                    //     moment().startOf('week').subtract(1, 'week'),
                    //     moment().endOf('week').subtract(1, 'week'),
                    // ],
                    'Este Mes': [moment().startOf('month'), moment().endOf('month')],
                    'El mes anterior': [
                        moment().startOf('month').subtract(1, 'month'),
                        moment().endOf('month').subtract(1, 'month'),
                    ],
                    'Este Año': [moment().startOf('year'), moment().endOf('year')],
                    'El Año anterior': [
                        moment().startOf('year').subtract(1, 'year'),
                        moment().endOf('year').subtract(1, 'year'),
                    ],
                }}
                {...props}
            />
        </ConfigProvider>
    );
};

export default DateRangePicker;
