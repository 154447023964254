import React, { useEffect, useState } from 'react';
import { ExtraListProps, OptionsProps, ProductProps } from '../../types/types.ds';
import { Button, Col, Input, notification, Row, Tag, Typography } from 'antd';
import ExtraItemCard from './components/Cards/ExtraItemCard';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { observer } from 'mobx-react-lite';

const { Title } = Typography;

interface IExtraForm {
    extras: ExtraListProps[];
    product_related: ProductProps;
    unit_price: string;
    quantity: number;
    special_instructions: string;
    handleClickUpdateItem: (
        extras: ExtraListProps[],
        unit_price: string,
        quantity: number,
        special_instructions: string
    ) => void;
    handleOk: () => void;
    handleCancel: () => void;
}

const ExtraForm = (props: IExtraForm) => {
    const [quantity, setQuantity] = useState<number>(props.quantity);
    const [notes, setNotes] = useState(props.special_instructions);
    const [extrasList, setExtras] = useState<ExtraListProps[]>(props.extras);
    const [unit_price, setUnitPrice] = useState<string>(props.unit_price);

    useEffect(() => {
        props.handleClickUpdateItem(extrasList, unit_price, quantity, notes);
    }, [extrasList, unit_price, quantity, notes]);

    /***
     * Set an option to selected, validate min, max and multiple constraints
     * @param index
     * @param option
     */
    const handleClickOption = (index: number, option: OptionsProps) => {
        const { multiple, max, min } = extrasList[index];

        // Verify that only one option is enabled when multiple is not enabled
        if (!multiple) {
            extrasList[index] = {
                ...extrasList[index],
                options: extrasList[index].options.map(o => {
                    return {
                        ...o,
                        picked: false,
                    };
                }),
            };
        }

        // Validate max only when the option is selected
        if (max && !option?.picked) {
            const c = extrasList[index].options.filter(option => option.picked)?.length + 1;
            if (c > max) {
                notification.error({
                    message: 'Error',
                    description: `Máximo ${max} opciones`,
                    placement: 'bottomLeft',
                });
                return;
            }
        }

        extrasList[index] = {
            ...extrasList[index],
            options: extrasList[index].options.map(o => {
                if (o.uid == option.uid) {
                    return {
                        ...o,
                        picked: !option.picked,
                    };
                }
                return {
                    ...o,
                };
            }),
        };

        // const _price = extrasList[index].options

        // get all picked options and sum their price from extrasList

        const _adding_prices = extrasList
            .filter(e => e.pricing == 'add')
            .map((extra, i) => {
                const _pickedOptions = extra.options.filter(o => o.picked).map(o => parseFloat(o.extra_price));
                return _pickedOptions.reduce((a, b) => a + b, 0);
            });

        const replacing_price = extrasList
            .filter(e => e.pricing == 'replace')
            .map((extra, i) => {
                const _pickedOptions = extra.options.filter(o => o.picked).map(o => parseFloat(o.extra_price));
                return Math.max(..._pickedOptions);
            });

        if (replacing_price.length > 0) {
            const _max = Math.max(...replacing_price);
            setUnitPrice(_max.toString());
        } else {
            setUnitPrice(props.product_related.price + _adding_prices.reduce((a, b) => a + b, 0).toString());
        }

        setExtras([...extrasList]);
    };

    return (
        <div
            className={'shadow'}
            style={{
                height: '100%',
            }}
        >
            <Row
                style={{
                    height: extrasList.length > 0 ? '55%' : '100%',
                    overflowY: 'auto',
                }}
            >
                <Col span={24}>
                    {extrasList.map((extra, indexOfExtra) => {
                        const extra_top = (
                            <Col span={24}>
                                <div className={'flex flex-row items-center justify-between'}>
                                    <Title level={4}>{extra.name}</Title>
                                    <Tag color={'green'}>
                                        {extra.multiple && extra.max > 0 ? `Selecione ${extra.max} opciones` : ''}
                                        {extra.multiple && extra.max == 0 ? `Selecione multiple opciones` : ''}
                                        {!extra.multiple && extra.min > 0 ? `Selecione ${extra.min} opciones` : ''}
                                        {!extra.multiple ? `Selecione 1 opcion` : ''}
                                    </Tag>
                                </div>
                            </Col>
                        );
                        const extra_content = extra.options.map(option => {
                            return (
                                <Col
                                    xxl={4}
                                    xl={6}
                                    md={12}
                                    sm={24}
                                    xs={24}
                                    key={option.name}
                                    style={{
                                        padding: 2,
                                    }}
                                    onClick={() => handleClickOption(indexOfExtra, option)}
                                >
                                    <ExtraItemCard
                                        title={`${option.name}`}
                                        price={option.extra_price}
                                        pricing={extra.pricing}
                                        picked={option.picked}
                                    />
                                </Col>
                            );
                        });

                        return (
                            <Row key={extra.name}>
                                {extra_top}
                                {extra_content}
                            </Row>
                        );
                    })}
                </Col>
            </Row>

            <Row
                style={{
                    height: '100%',
                    overflowY: 'auto',
                }}
            >
                <Col span={24}>
                    <Row>
                        <Col span={24}>
                            <label>Notas:</label>
                            <Input.TextArea defaultValue={notes} onChange={t => setNotes(t.target.value)} />
                        </Col>
                    </Row>
                </Col>
                <Col
                    span={24}
                    style={{
                        marginTop: 5,
                    }}
                >
                    <div className={'flex flex-col justify-center items-center'}>
                        <div>
                            <Button.Group>
                                <Button
                                    className={'button-quantity'}
                                    size={'large'}
                                    shape={'circle'}
                                    icon={<MinusOutlined />}
                                    onClick={() => setQuantity(quantity - 1)}
                                />
                                <div className={'mx-2'}>
                                    <Input
                                        value={quantity}
                                        style={{
                                            textAlign: 'center',
                                        }}
                                        onChange={e => setQuantity(parseInt(e.target.value))}
                                    />
                                </div>
                                <Button
                                    className={'button-quantity'}
                                    size={'large'}
                                    shape={'circle'}
                                    icon={<PlusOutlined />}
                                    onClick={() => setQuantity(quantity + 1)}
                                />
                            </Button.Group>
                        </div>
                        <div className={'flex flex-col justify-center items-center'}>
                            <div
                                style={{
                                    minWidth: 300,
                                }}
                            >
                                <Button type={'default'} size={'large'} onClick={props.handleOk}>
                                    Aceptar
                                </Button>
                            </div>
                            {/*<div*/}
                            {/*    className={'mt-1'}*/}
                            {/*    style={{*/}
                            {/*        minWidth: 200,*/}
                            {/*    }}*/}
                            {/*>*/}
                            {/*    <Button*/}
                            {/*        danger*/}
                            {/*        style={{*/}
                            {/*            color: 'white',*/}
                            {/*            backgroundColor: 'red',*/}
                            {/*        }}*/}
                            {/*        size={'large'}*/}
                            {/*        onClick={props.handleCancel}*/}
                            {/*    >*/}
                            {/*        Cancelar*/}
                            {/*    </Button>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default observer(ExtraForm);
