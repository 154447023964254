import { Card } from 'antd';
import React from 'react';

const ExtraItemCard = (props: { title: string; price: string; pricing: 'add' | 'replace'; picked: boolean }) => {
    const cslPicked = props.picked ? 'background-primary-color' : '';

    return (
        <Card
            className={`card-item ${cslPicked}`}
            style={{
                borderLeftWidth: 10,
                borderLeftColor: '#fff',
                minHeight: 60,
                borderRadius: 10,
            }}
        >
            <div className={'flex flex-col justify-end'}>
                <div className={'mb-auto'}>
                    <div
                        style={{
                            fontWeight: 'bold',
                            fontSize: 18,
                        }}
                    >
                        {props.title}
                    </div>
                    {/*<div>*/}
                    {/*    {props.pricing == 'add' ? '+' : ''} ${props.price}*/}
                    {/*</div>*/}
                </div>
            </div>
        </Card>
    );
};

export default ExtraItemCard;
