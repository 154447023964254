import { action, observable, computed, runInAction } from 'mobx';
import { get, patch, post } from '../utils/request';
import dayjs from 'dayjs';
import { OrderProps, IInvoiceStatus } from '../types/types.ds';

export interface ILocalOrder {
    id: number;
    uid: string;
    invoices: {
        uid: string;
        status: IInvoiceStatus;
        number: string;
        sri_response: string;
        sent: boolean;
    }[];

    created_time: Date;
    modified_time: Date;
    active: boolean;
    deleted: boolean;
    published: boolean;
    number: number;
    type: string;
    table: number;
    table_name: string;
    first_name: string;
    last_name: string;
    billing_name: string;
    identification_number: null | string;
    identification_type: null | string;
    shipping_street: string;
    shipping_city: string;
    shipping_state: null;
    customer_email: null;
    phone: string;
    shipping_zip_code: null;
    shipping_cost: string;
    status: string;
    notes: null | string;
    location: null;
    pickup_time: null;
    estimated_arrival_time: null;
    _subtotal: null | string;
    discount: string;
    tip: string;
    source: null;
    cancel_reason: null;
    site: null;
    owner: number;
    created_by: number;
    modified_by: number | null;
    delivery_driver: null;
    payment_type: null;
    coupons: any[];
    total_without_tax: number;
    total_taxes: number;
}

class Order {
    @observable loading = true;
    @observable orders: Array<ILocalOrder> = [];
    @observable next_page = null;
    @observable total_orders = 0;
    @observable selected?: ILocalOrder;

    @action setSelected = async (order: ILocalOrder) => {
        this.selected = order;
        await setTimeout(() => {}, 300);
    };

    @action getOrders(params?: any) {
        this.loading = true;

        get('/restaurant/local-orders/', params)
            .then(r => {
                this.orders = r.data.results;
                this.next_page = r.data?.links.next;
                this.total_orders = r.data.count;
            })
            .catch(err => console.log(JSON.stringify(JSON.stringify(err))))
            .finally(() => {
                runInAction(() => {
                    this.loading = false;
                });
            });
    }

    @action
    async setInvoice(invoice: any) {
        this.orders = this.orders.map(o => {
            return {
                ...o,
                invoices: o.invoices.map(i => {
                    if (i.uid == invoice.uid) {
                        return {
                            ...invoice,
                        };
                    } else {
                        return {
                            ...i,
                        };
                    }
                }),
            };
        });
    }
}

export const LocalOrderStore = new Order();
