import { Button, Form, Input, Checkbox, Select, Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import DateRangePicker from '../../../../components/DateRangePicker';
import { useHistory } from 'react-router-dom';

const OrderFilter: React.FC = () => {
    const [form] = Form.useForm();
    const history = useHistory();
    const [, forceUpdate] = useState({});

    // To disable submit button at the beginning.
    useEffect(() => {
        forceUpdate({});
    }, []);

    const onFinish = (values: any) => {
        console.log('Finish:', values);
        const params: any = {};

        if (values.search) {
            params['search'] = values.search;
        }

        if (values.display_only) {
            params['display_only'] = values.display_only;
        }

        if (values.is_pending_authorization) {
            params['is_pending_authorization'] = values.is_pending_authorization;
        }

        if (values.created_time) {
            params['created_time_min'] = values.created_time[0]?.format('YYYY-MM-DD');
            params['created_time_max'] = values.created_time[1]?.format('YYYY-MM-DD');
        }

        const queryString = Object.keys(params)
            .map(key => key + '=' + params[key])
            .join('&');

        history.replace(`/orders/?${queryString}`);
    };

    return (
        <Form form={form} onFinish={onFinish}>
            <Row>
                <Col xs={24} md={24} lg={6}>
                    <div className={'ml-0'}>
                        <Form.Item name="search" label={'Buscar'}>
                            <Input
                                placeholder="Buscar"
                                style={{
                                    maxWidth: 300,
                                }}
                            />
                        </Form.Item>
                    </div>
                </Col>

                <Col xs={24} md={24} lg={6}>
                    <div className={'ml-2'}>
                        <Form.Item name="created_time" label={'Fecha'}>
                            <DateRangePicker />
                        </Form.Item>
                    </div>
                </Col>

                <Col xs={24} md={24} lg={6}>
                    <div className={'ml-2'}>
                        <Form.Item name="display_only" label={'Mostrar Solo'}>
                            <Select
                                dropdownMatchSelectWidth={false}
                                style={{
                                    maxWidth: 300,
                                }}
                            >
                                <Select.Option>Todas</Select.Option>
                                <Select.Option value="invoiced">Facturados</Select.Option>
                                <Select.Option value="not_invoiced">No Facturados</Select.Option>
                            </Select>
                        </Form.Item>
                    </div>
                </Col>

                <Col xs={24} md={24} lg={6}>
                    <div className={'ml-2'}>
                        <Form.Item shouldUpdate>
                            {() => (
                                <Button type="primary" htmlType="submit">
                                    Buscar
                                </Button>
                            )}
                        </Form.Item>
                    </div>
                </Col>
            </Row>
        </Form>
    );
};

export default OrderFilter;
