import React, { useEffect, useState } from 'react';
import {
    Button,
    Card,
    Col,
    Divider,
    Drawer,
    Empty,
    Input,
    message,
    Modal,
    notification,
    Row,
    Typography,
    Spin,
} from 'antd';
import { ArrowLeftOutlined, CloseOutlined, SearchOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import { EditOutlined, LocalPrintshop, PointOfSale, Save as SaveIcon, CheckCircle } from '@mui/icons-material';

import {
    CategoryProps,
    ExtraListProps,
    LineItemProps,
    OrderProps,
    ProductProps,
    UserProps,
    RestaurantTablesProps,
} from '../../types/types.ds';
import { observer } from 'mobx-react';
import LocalOrderStore from './models/LocalOrderStore';
import ExtraForm from './ExtraForm';
import { colors, getNextColor } from './utils/contants';
import OrderItemCard from './components/Cards/CartItemCard/OrderItemCard';
import CategoryCard from './components/Cards/CategoryCard';
import ProductCard from './components/Cards/ProductCard';
import CustomerForm from './CustomerForm';
import { POSCompanyStore } from './models/POSCompanyStore';
import { UserStore } from '../../stores/UserStore';
import { OrderStore } from '../../stores/OrderStore';
import { useHistory } from 'react-router-dom';
import PrinterStore from '../../stores/PrinterStore';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import KeyBoard from './components/KeyBoard';
import ReceiptIcon from '@mui/icons-material/Receipt';
import InvoiceStore from './models/InvoiceStore';
import OrderStatusTag from '../PointOfSales/components/OrderStatusTag';

const { Title } = Typography;

const OrderForm = ({
    order,
    table,
    isLoading,
}: {
    order: OrderProps;
    table: RestaurantTablesProps;
    isLoading?: boolean;
}) => {
    let generator1 = getNextColor();

    const { width, height } = useWindowDimensions();

    const [isSendingOrder, setIsSendingOrder] = useState(false);
    const [category, setCategory] = useState<CategoryProps | null>(null);
    const [selectedOrderItem, setSelectedOrderItem] = useState<LineItemProps | null>(null);
    const [showEditCustomerForm, setShowEditCustomerForm] = useState<boolean>(false);
    const [IsPaymentModalVisible, setShowPaymentModal] = useState<boolean>(false);
    const [paymentValue, setPaymentValue] = useState<number>(0);
    const [showKeyboard, setShowKeyboard] = useState<boolean>(false);

    const history = useHistory();

    const localStore = LocalOrderStore;

    const handleClickCategory = (c: CategoryProps) => {
        setSelectedOrderItem(null);
        if (category?.uid === c.uid) {
            setCategory(null);
        } else {
            setCategory(c);
        }
    };
    const handleClickProduct = (product: ProductProps) => {
        const next_uid = (order?.order_items.length ?? 0) + 1;
        const quantity = 1;
        const total_price = parseFloat(product.price);

        const unit_price = localStore?.getUnitPrice(product, product.extras_list) ?? 0;

        //Calculate unit price without tax
        const unit_price_without_taxes = unit_price / (1 + parseFloat(product.tax_rate) / 100);
        const subtotal_without_tax = unit_price_without_taxes * quantity;
        const total_taxes = (unit_price_without_taxes * parseFloat(product.tax_rate)) / 100;
        const total_with_taxes = subtotal_without_tax + total_taxes;

        // alert(subtotal_without_tax);

        const order_item: LineItemProps = {
            uid: next_uid.toString(),
            product: product.uid,
            product_name: product.name,
            product_related: { ...product },
            quantity: 1,
            extras: [...product.extras_list],

            unit_price: unit_price.toFixed(2), // This values includes taxes and should be sent to the order
            total_price: total_price.toFixed(2), // This values includes taxes and should sent to the order

            subtotal_without_tax: subtotal_without_tax,
            total_taxes: total_taxes,
            total_with_taxes: total_with_taxes,

            original_total_price: total_price.toFixed(2),
            special_instructions: '',
        };

        const items = localStore?.order?.order_items ?? [];

        localStore?.updateOrderField('order_items', [...items, order_item]);

        setSelectedOrderItem(order_item);
    };

    const handleClickUpdateItem = (
        order_item_uid: string,
        extrasList: ExtraListProps[],
        unit_price: string,
        quantity: number,
        special_instructions: string
    ) => {
        const items =
            localStore?.order?.order_items.map(item => {
                if (item.uid == order_item_uid) {
                    const unit_price_without_taxes =
                        parseFloat(unit_price) / (1 + parseFloat(item.product_related.tax_rate) / 100);

                    const subtotal_without_tax = unit_price_without_taxes * quantity;

                    const total_taxes = subtotal_without_tax * (parseFloat(item.product_related.tax_rate) / 100);

                    const total_with_taxes = subtotal_without_tax + total_taxes;

                    return {
                        ...item,
                        extras: extrasList,
                        quantity: quantity,
                        unit_price: unit_price,
                        special_instructions: special_instructions,

                        subtotal_without_tax: subtotal_without_tax,
                        total_taxes: total_taxes,
                        total_with_taxes: total_with_taxes,
                    };
                }
                return item;
            }) ?? [];
        localStore?.updateOrderField('order_items', [...items]);
    };

    const handleClickRemoveItem = (order_item: LineItemProps) => {
        setSelectedOrderItem(null);
        const items = localStore?.order?.order_items.filter(i => i.uid != order_item.uid) ?? [];
        localStore?.updateOrderField('order_items', items);
    };

    const handlePrint = async (order_id: string) => {
        const text = await OrderStore.getOrderReceipt(order_id);
        await PrinterStore.printText(text).catch(() => {
            notification.error({
                message: 'No se pudo imprimir!',
                description: 'Error',
                placement: 'bottom',
            });
        });
    };

    const placeOrder = async (print: boolean) => {
        if (!order) {
            message.error('Seleccione al menos un producto');
            return;
        }
        if (order.status == 'delivered') {
            message.error('La orden ya fue completada');
            return;
        }

        // if (!order.uid) {
        //     message.error('Seleccione una mesa');
        //     return;
        // }

        setIsSendingOrder(true);

        const payload: any = {
            table: table.uid,
            first_name: order?.first_name,
            last_name: order?.last_name,
            billing_name: order?.billing_name,
            identification_number: order?.identification_number,
            identification_type: order?.identification_type,
            shipping_street: order.shipping_street,
            shipping_city: UserStore.user?.city,
            customer_email: order?.customer_email,
            phone: order.phone,

            type: 'on-site',
            owner: order.owner_related.uid,
            shipping_cost: order.shipping_cost,
            notes: order.notes,

            status: 'in-progress',
            order_items: order.order_items.map(order_item => {
                return {
                    product: order_item.product_related.uid,
                    product_name: order_item.product_related.name,
                    extras: order_item.extras,
                    quantity: order_item.quantity,
                    unit_price: parseFloat(order_item.unit_price).toFixed(2),
                    total_price: order_item.total_with_taxes.toFixed(2),
                    special_instructions: order_item.special_instructions,
                };
            }),
        };

        //If order id is 0 we should create a new one

        setIsSendingOrder(true);

        if (order?.id && order.id == -1) {
            const data = await OrderStore.placeOrder(payload);
            LocalOrderStore.setOrder(data);
        } else {
            const data = await OrderStore.updateOrder(order.uid, payload);
            LocalOrderStore.setOrder(data);
        }
        LocalOrderStore.setIsPendingToSave(false);

        if (print) {
            await handlePrint(order.uid);
        }
        setIsSendingOrder(false);
    };
    const products = POSCompanyStore.products.filter(p => (category ? p.category_related.uid == category?.uid : true));
    const categories = POSCompanyStore.categories;
    const order1 = width >= 576 ? 1 : 2;
    const order2 = width >= 576 ? 2 : 1;

    const order_items = [...order.order_items];

    const changeValue = paymentValue > 0 ? paymentValue - order?.total_with_taxes ?? 0 : 0;

    const isOrderCompleted = order?.status == 'delivered';

    return (
        <div>
            <Row>
                <Col className={'main-cols'} xs={24} sm={14} md={13} lg={15} xl={15} xxl={15} order={order1}>
                    {/*<Row*/}
                    {/*    style={{*/}
                    {/*        height: '6%',*/}
                    {/*        display: 'flex',*/}
                    {/*        flexDirection: 'row',*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    <Col span={24}>*/}
                    {/*        <div className={'flex flex-row items-center'}>*/}
                    {/*            <ArrowLeftOutlined*/}
                    {/*                style={{*/}
                    {/*                    fontSize: 20,*/}
                    {/*                    marginRight: 10,*/}
                    {/*                }}*/}
                    {/*                onClick={() => {*/}
                    {/*                    history.push('/');*/}
                    {/*                }}*/}
                    {/*            />*/}
                    {/*            <Input*/}
                    {/*                prefix={<SearchOutlined />}*/}
                    {/*                placeholder={'Buscar'}*/}
                    {/*                size={'large'}*/}
                    {/*                style={{*/}
                    {/*                    maxWidth: 280,*/}
                    {/*                }}*/}
                    {/*                bordered*/}
                    {/*            />*/}
                    {/*        </div>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}

                    <Row
                        className={'wrapper-categories'}
                        style={{
                            width: '100%',
                            overflowY: 'auto',
                            overflowX: 'auto',
                            flexWrap: 'wrap',
                        }}
                    >
                        {categories.map(c => {
                            const color = generator1.next().value;
                            if (!color) {
                                generator1 = getNextColor();
                            }
                            return (
                                <Col
                                    md={8}
                                    sm={8}
                                    xs={12}
                                    xl={3}
                                    xxl={3}
                                    key={c.uid}
                                    style={{
                                        padding: 2,
                                    }}
                                    onClick={() => handleClickCategory(c)}
                                >
                                    <CategoryCard
                                        title={c.name}
                                        count={4}
                                        color={color ? color : colors[0]}
                                        isSelected={category?.uid == c.uid}
                                    />
                                </Col>
                            );
                        })}
                    </Row>
                    <Row
                        className={'wrapper-divider'}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                        }}
                    >
                        <Divider />
                    </Row>
                    <Row
                        className={'wrapper-products'}
                        style={{
                            overflowY: 'auto',
                        }}
                    >
                        <Col span={24}>
                            <Row>
                                {selectedOrderItem?.uid ? (
                                    <Col span={24} key={selectedOrderItem.uid}>
                                        <ExtraForm
                                            extras={selectedOrderItem.extras ?? []}
                                            product_related={selectedOrderItem.product_related}
                                            unit_price={selectedOrderItem.unit_price}
                                            quantity={selectedOrderItem.quantity}
                                            special_instructions={selectedOrderItem.special_instructions ?? ''}
                                            handleClickUpdateItem={(
                                                extraList,
                                                unit_price,
                                                quantity,
                                                special_instructions
                                            ) =>
                                                handleClickUpdateItem(
                                                    selectedOrderItem?.uid,
                                                    extraList,
                                                    unit_price,
                                                    quantity,
                                                    special_instructions
                                                )
                                            }
                                            handleOk={() => setSelectedOrderItem(null)}
                                            handleCancel={() => {
                                                handleClickRemoveItem(selectedOrderItem);
                                                setSelectedOrderItem(null);
                                            }}
                                        />
                                    </Col>
                                ) : (
                                    products.map(p => {
                                        return (
                                            <Col
                                                xxl={4}
                                                xl={4}
                                                md={8}
                                                sm={12}
                                                xs={12}
                                                key={p.uid}
                                                style={{
                                                    padding: 2,
                                                }}
                                                onClick={() => handleClickProduct(p)}
                                            >
                                                <ProductCard title={p.name} count={4} color={colors[0]} />
                                            </Col>
                                        );
                                    })
                                )}
                            </Row>
                            {/*<pre>{JSON.stringify(order.order_items, null, 3)}</pre>*/}
                        </Col>
                    </Row>
                </Col>
                <Col className={'main-cols'} xs={24} sm={10} md={11} lg={9} xl={9} xxl={9} order={order2}>
                    <Row className={'wrapper-order-header'}>
                        <Col span={24}>
                            <div className={'flex flex-row justify-between items-center'}>
                                <div>
                                    <div
                                        className={'flex flex-col items-left'}
                                        onClick={() => {
                                            history.push('/');
                                        }}
                                    >
                                        <div className={'flex flex-row items-center'}>
                                            <ArrowLeftOutlined
                                                style={{
                                                    fontSize: 30,
                                                }}
                                            />
                                            <Title
                                                level={3}
                                                style={{
                                                    margin: 0,
                                                    marginLeft: 10,
                                                }}
                                            >
                                                Mesa: {table.name} <OrderStatusTag status={order.status} />
                                            </Title>
                                            <Spin spinning={isLoading} />
                                        </div>
                                        <div
                                            className={'secondary-color'}
                                            style={{
                                                fontSize: 14,
                                            }}
                                        >
                                            {order.first_name} {order.last_name} {order.identification_number}
                                        </div>
                                    </div>
                                </div>

                                {!isOrderCompleted ? (
                                    <div
                                        style={{
                                            border: '1px #fff solid',
                                            borderRadius: '50%',
                                            width: '25px',
                                            height: '25px',
                                            textAlign: 'center',
                                            alignItems: 'center',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        <EditOutlined
                                            onClick={() => setShowEditCustomerForm(true)}
                                            style={{
                                                fontSize: 18,
                                            }}
                                        />
                                    </div>
                                ) : null}
                            </div>
                        </Col>
                    </Row>
                    <Row
                        className={'wrapper-order-items'}
                        style={{
                            overflowY: 'hidden',
                        }}
                    >
                        <Col
                            span={24}
                            style={{
                                overflowY: 'auto',
                                height: '96%',
                            }}
                        >
                            {order.order_items.length == 0 && (
                                <Empty
                                    image={
                                        <ShoppingCartOutlined
                                            style={{
                                                fontSize: 50,
                                            }}
                                        />
                                    }
                                    description={'No ha seleccionado ningun producto'}
                                />
                            )}
                            {order_items.reverse().map(item => {
                                return (
                                    <div key={item.uid} className={'mt-1'}>
                                        <OrderItemCard
                                            isSelected={selectedOrderItem?.uid == item.uid}
                                            order_item={item}
                                            handleClickItem={() => {
                                                if (selectedOrderItem?.uid == item.uid) {
                                                    setSelectedOrderItem(null);
                                                } else {
                                                    setSelectedOrderItem({
                                                        ...item,
                                                    });
                                                }
                                            }}
                                            handleClickRemoveItem={() => handleClickRemoveItem(item)}
                                        />
                                    </div>
                                );
                            })}

                            {/*<pre>{JSON.stringify(order.order_items, null, 3)}</pre>*/}
                        </Col>
                    </Row>
                    <Row
                        className={'wrapper-order-footer'}
                        onClick={() => {
                            setSelectedOrderItem(null);
                        }}
                        style={{
                            overflow: 'hidden',
                        }}
                    >
                        <Col span={24} className={'overflow-y-auto'}>
                            <Card
                                style={{
                                    width: '100%',
                                    height: '100%',
                                }}
                                bodyStyle={{
                                    height: '100%',
                                }}
                            >
                                <div className={'flex flex-col  h-3/4'}>
                                    <div className={'flex flex-row justify-between'}>
                                        <div>Subtotal</div>
                                        <div>${order.total_without_tax.toFixed(2)}</div>
                                    </div>
                                    <div className={'flex flex-row justify-between'}>
                                        <div>IVA</div>
                                        <div>${order.total_taxes.toFixed(2)}</div>
                                    </div>
                                    <Divider />
                                    <div className={'flex flex-row justify-between items-center'}>
                                        <Title level={4}>Total</Title>
                                        <Title level={4}>${order.total_with_taxes.toFixed(2)}</Title>
                                    </div>
                                </div>
                                <div
                                    className={'h-1/4'}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'flex-end',
                                        alignItems: 'flex-end',
                                        overflowY: 'auto',
                                    }}
                                >
                                    <Col xs={8} sm={8} md={8} lg={8}>
                                        <Button
                                            disabled={!localStore.IsPendingToSave}
                                            style={{
                                                width: '100%',
                                                borderRadius: 20,
                                            }}
                                            loading={isSendingOrder}
                                            onClick={() => placeOrder(false)}
                                            type={'default'}
                                            size={'large'}
                                        >
                                            <SaveIcon
                                                style={{
                                                    fontSize: 30,
                                                }}
                                            />
                                        </Button>
                                    </Col>
                                    <Col xs={8} sm={8} md={8} lg={8}>
                                        <Button
                                            style={{
                                                width: '100%',
                                                borderRadius: 20,
                                            }}
                                            disabled={localStore.IsPendingToSave}
                                            loading={isSendingOrder}
                                            onClick={() => handlePrint(order.uid)}
                                            type={'default'}
                                            size={'large'}
                                        >
                                            <LocalPrintshop
                                                style={{
                                                    fontSize: 30,
                                                }}
                                            />
                                        </Button>
                                    </Col>

                                    <Col xs={8} sm={8} md={8} lg={8}>
                                        <Button
                                            // disabled={localStore.IsPendingToSave}
                                            style={{
                                                width: '100%',
                                                borderRadius: 20,
                                            }}
                                            loading={isSendingOrder}
                                            onClick={() => {
                                                setShowPaymentModal(true);
                                            }}
                                            type={'default'}
                                            size={'large'}
                                        >
                                            <PointOfSale
                                                style={{
                                                    fontSize: 30,
                                                }}
                                            />
                                        </Button>
                                    </Col>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Drawer
                placement="left"
                title={selectedOrderItem?.product_related?.name ?? ''}
                onClose={() => setSelectedOrderItem(null)}
                open={false}
                width={'50%'}
                closeIcon={
                    <CloseOutlined
                        style={{
                            fontSize: 30,
                        }}
                    />
                }
                bodyStyle={{
                    height: '100vh',
                }}
            >
                {selectedOrderItem?.uid && (
                    <ExtraForm
                        extras={selectedOrderItem.extras ?? []}
                        product_related={selectedOrderItem.product_related}
                        unit_price={selectedOrderItem.unit_price}
                        quantity={selectedOrderItem.quantity}
                        special_instructions={selectedOrderItem.special_instructions ?? ''}
                        handleClickUpdateItem={(extraList, unit_price, quantity, special_instructions) =>
                            handleClickUpdateItem(
                                selectedOrderItem?.uid,
                                extraList,
                                unit_price,
                                quantity,
                                special_instructions
                            )
                        }
                        handleOk={() => setSelectedOrderItem(null)}
                        handleCancel={() => {
                            handleClickRemoveItem(selectedOrderItem);
                            setSelectedOrderItem(null);
                        }}
                    />
                )}
            </Drawer>

            <Modal
                title={'Datos del Cliente'}
                visible={showEditCustomerForm}
                onCancel={() => setShowEditCustomerForm(false)}
                footer={[]}
                zIndex={1000}
            >
                {showEditCustomerForm && (
                    <CustomerForm
                        contact={{
                            identification: order?.identification_number ?? '',
                            billing_name: order?.billing_name,
                            identification_type: order?.identification_type ?? '',
                            email: order?.customer_email ?? '',
                            phone: order?.phone,
                            address: order?.shipping_street,
                        }}
                        handleChange={values => {
                            localStore?.updateOrderField('billing_name', values.billing_name);
                            localStore?.updateOrderField('identification_number', values.identification);
                            localStore?.updateOrderField('identification_type', values.identification_type);
                            localStore?.updateOrderField('phone', values.phone);
                            localStore?.updateOrderField('shipping_street', values.address);
                            localStore?.updateOrderField('customer_email', values.email);
                            setShowEditCustomerForm(false);
                        }}
                    />
                )}
            </Modal>

            <Modal
                title={'Pago'}
                visible={IsPaymentModalVisible}
                onCancel={() => setShowPaymentModal(false)}
                footer={[]}
                zIndex={1}
            >
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Row className={'w-full'}>
                        <Col span={24}>
                            <div className={'w-full flex flex-row items-center justify-between'}>
                                <div>Cliente</div>

                                <div
                                    style={{
                                        fontWeight: 'bold',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                        if (!isOrderCompleted) setShowEditCustomerForm(true);
                                    }}
                                >
                                    {!isOrderCompleted ? (
                                        <div>
                                            <EditOutlined
                                                style={{
                                                    fontSize: 25,
                                                    color: 'green',
                                                }}
                                            />
                                        </div>
                                    ) : null}
                                    <div>
                                        {order?.billing_name} -{order?.identification_number}
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Divider />
                    <Row className={'w-full'}>
                        <Col span={24}>
                            <div className={'flex flex-col  h-3/4'}>
                                <div className={'flex flex-row justify-between'}>
                                    <div>Subtotal</div>
                                    <div>${order.total_without_tax.toFixed(2)}</div>
                                </div>
                                <div className={'flex flex-row justify-between'}>
                                    <div>IVA</div>
                                    <div>${order.total_taxes.toFixed(2)}</div>
                                </div>
                                <div className={'flex flex-row justify-between items-center'}>
                                    <Title level={4}>Total</Title>
                                    <Title level={4}>${order.total_with_taxes.toFixed(2)}</Title>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Divider />
                    <Row className={'w-full'}>
                        <Col span={24}>
                            <div
                                className={'w-full flex flex-row items-center justify-between'}
                                onClick={() => {
                                    setShowKeyboard(!showKeyboard);
                                }}
                            >
                                <div>Pago</div>

                                <div className={'flex flex-row items-center'}>
                                    <EditOutlined
                                        style={{
                                            fontSize: 25,
                                            color: 'green',
                                        }}
                                    />
                                    <div
                                        style={{
                                            fontSize: 20,
                                            fontWeight: 'bold',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        - ${paymentValue.toFixed(2)}
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    {changeValue > 0 && (
                        <Row className={'w-full'}>
                            <Col span={24}>
                                <div className={'w-full flex flex-row items-center justify-between'}>
                                    <div>Cambio</div>
                                    <div
                                        style={{
                                            fontSize: 30,
                                            fontWeight: 'bold',
                                            fontStyle: 'italic',
                                        }}
                                    >
                                        ${changeValue.toFixed(2)}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    )}

                    {!showKeyboard && (
                        <Row className={'w-full mt-5'}>
                            <Col xs={24} className={'mt-2'}>
                                <Button
                                    disabled={!localStore.IsPendingToSave}
                                    style={{
                                        width: '100%',
                                        borderRadius: 20,
                                    }}
                                    loading={isSendingOrder}
                                    onClick={() => placeOrder(false)}
                                    type={'default'}
                                    size={'large'}
                                >
                                    <SaveIcon
                                        style={{
                                            fontSize: 30,
                                        }}
                                    />
                                    Guardar
                                </Button>
                            </Col>
                            <Col xs={24} className={'mt-2'}>
                                <Button
                                    disabled={localStore.IsPendingToSave}
                                    loading={OrderStore.isCreatingInvoice}
                                    style={{
                                        width: '100%',
                                        borderRadius: 20,
                                    }}
                                    onClick={async () => {
                                        if (
                                            order.identification_number === '9999999999999' &&
                                            order.total_with_taxes >= 50
                                        ) {
                                            notification.error({
                                                key: 'consumidorFinalError',
                                                message: 'Error',
                                                description:
                                                    "No se puede facturar a 'Consumidor Final' con un total mayor a 50",
                                            });
                                        } else {
                                            const data = await OrderStore.createInvoice(order.uid).catch(e => {
                                                notification.error({
                                                    message: 'Error',
                                                    description: e.message,
                                                });
                                            });

                                            POSCompanyStore.completeOrder(table);

                                            const text = await InvoiceStore.getInvoiceTicket(data.uid);
                                            await PrinterStore.printText(text);

                                            POSCompanyStore.getTables();
                                            LocalOrderStore.setOrder(null);
                                            history.push('/');
                                        }
                                    }}
                                    type={'default'}
                                    size={'large'}
                                >
                                    <ReceiptIcon />
                                    Facturar y Completar
                                </Button>
                            </Col>
                            <Col xs={24} className={'mt-2'}>
                                <Button
                                    title={'Cerrar mesa'}
                                    disabled={localStore.IsPendingToSave}
                                    style={{
                                        width: '100%',
                                        borderRadius: 20,
                                    }}
                                    loading={isSendingOrder}
                                    onClick={() => {
                                        POSCompanyStore.completeOrder(table);
                                        POSCompanyStore.getTables();
                                        LocalOrderStore.setOrder(null);
                                        history.push('/');
                                    }}
                                    type={'default'}
                                    size={'large'}
                                >
                                    <CheckCircle
                                        style={{
                                            fontSize: 30,
                                        }}
                                    />
                                    Completar
                                </Button>
                            </Col>
                        </Row>
                    )}

                    {showKeyboard && (
                        <Row className={'w-full'}>
                            <Col span={24}>
                                <KeyBoard
                                    onKeyClick={key => {
                                        if (key === '←') {
                                            setPaymentValue(0);
                                        } else if (key === 'ok') {
                                            setShowKeyboard(false);
                                        } else {
                                            const v = parseFloat(paymentValue.toString() + key);
                                            setPaymentValue(v);
                                        }
                                    }}
                                />
                            </Col>
                        </Row>
                    )}
                </div>
            </Modal>
        </div>
    );
};

export default observer(OrderForm);
